import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { useInView } from 'react-hook-inview'
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'

import { BackgroundLarge } from './module_01.style';
import { HeadlineWrapper } from './module_01.style';
import { HeadlineOne } from './module_01.style';
import { HeadlineTwo } from './module_01.style';
import { HeadlineThree } from './module_01.style';
import { Wrapper } from './module_01.style';
import { Line } from './module_01.style';

import { TextLarge } from './module_01.style';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {




}

const Module01   = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (


  <div>

    {/* LARGE */}

    <MediaQuery minWidth={1200}>
      <BackgroundLarge>
        <Container fluid>

        <div ref={ref}>
        {isVisible
          ? handleWaypointEnter()
          : ''
        }
        </div>

          <Row align="start" justify="start">
            <Col sm={4}>
              <Line/>
              <TextLarge style={{ marginBottom: '40px' }}>
                Solution
              </TextLarge>
              <TextLarge>
                Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.
              </TextLarge>
            </Col>
            <Col sm={4}>
              <Line/>
              <TextLarge style={{ marginBottom: '40px' }}>
                Solution
              </TextLarge>
              <TextLarge>
                Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.
              </TextLarge>
            </Col>
            <Col sm={4}>
              <Line/>
              <TextLarge style={{ marginBottom: '40px' }}>
                Solution
              </TextLarge>
              <TextLarge>
                Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.
              </TextLarge>
            </Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '2vw' }}>
                    <div></div>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* MEDIUM */}

    <MediaQuery minWidth={801} maxWidth={1199}>
      <BackgroundLarge>
        <Container fluid>

        <div ref={ref}>
        {isVisible
          ? handleWaypointEnter()
          : ''
        }
        </div>

          <Row align="start" justify="start">
            <Col sm={4}>
              <Line/>
              <TextLarge style={{ marginBottom: '40px' }}>
                Solution
              </TextLarge>
              <TextLarge>
                Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.
              </TextLarge>
            </Col>
            <Col sm={4}>
              <Line/>
              <TextLarge style={{ marginBottom: '40px' }}>
                Solution
              </TextLarge>
              <TextLarge>
                Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.
              </TextLarge>
            </Col>
            <Col sm={4}>
              <Line/>
              <TextLarge style={{ marginBottom: '40px' }}>
                Solution
              </TextLarge>
              <TextLarge>
                Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.
              </TextLarge>
            </Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '2vw' }}>
                    <div></div>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* SMALL */}

    <MediaQuery minWidth={1} maxWidth={800}>
      <BackgroundLarge>
        <Container fluid>

        <div ref={ref}>
        {isVisible
          ? handleWaypointEnter()
          : ''
        }
        </div>
        
          <Row align="start" justify="start">
            <Col sm={12} style={{ marginBottom: '60px' }}>
              <Line/>
              <TextLarge style={{ marginBottom: '40px' }}>
                Solution
              </TextLarge>
              <TextLarge>
                Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.
              </TextLarge>
            </Col>
            <Col sm={12} style={{ marginBottom: '60px' }}>
              <Line/>
              <TextLarge style={{ marginBottom: '40px' }}>
                Solution
              </TextLarge>
              <TextLarge>
                Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.
              </TextLarge>
            </Col>
            <Col sm={12} style={{ marginBottom: '60px' }}>
              <Line/>
              <TextLarge style={{ marginBottom: '40px' }}>
                Solution
              </TextLarge>
              <TextLarge>
                Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.
              </TextLarge>
            </Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '2vw' }}>
              <div></div>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

  </div>
)
}

export default Module01;
