import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { BackgroundLarge } from './ProjectGrid.style';
import { TextLarge } from './ProjectGrid.style';
import { TextLargeClean } from './ProjectGrid.style';
import { TitleLarge } from './ProjectGrid.style';
import { LetterOne } from './ProjectGrid.style';
import { LetterTwo } from './ProjectGrid.style';
import { LetterThree } from './ProjectGrid.style';
import { LogoTiny } from './ProjectGrid.style';
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'
import { useInView } from 'react-hook-inview'
import { HeadlineWrapper } from './ProjectGrid.style';
import { Wrapper } from './ProjectGrid.style';
import Typewriter from 'typewriter-effect/dist/core';

setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const C = CSSPlugin;

const handleWaypointEnter = () => {

  TweenMax.to(
    document.querySelector( String("."+TextLarge.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.2
  })
}

const AboutLarge = () => {

  let titleRef = useRef(null);

  let topLeftRef = useRef(null);
  let topRightRef = useRef(null);
  let centerRef = useRef(null);
  let bottomRef = useRef(null);

  useEffect(() => {
    TweenMax.to(
      titleRef,
      1,
    {
      y:"0px",
      ease:Expo.easeOut,
      delay:1
    })

    const title = new Typewriter(titleRef, {
      delay: 120,
    });
    title
      .callFunction(function(state) {
        state.elements.cursor.style.display = 'none';
      })
      .pauseFor(700)
      .typeString('This is')
      .pauseFor(1000)
      .deleteAll()
      .typeString('Typed―Systems')
      .pauseFor(1000)
      .start();

    const topLeft = new Typewriter(topLeftRef, {
      delay: 75,
    });
    topLeft
      .callFunction(function(state) {
        state.elements.cursor.style.display = 'none';
      })
      .typeString('〇 TypedSystems')
      .start();

    const topRight = new Typewriter(topRightRef, {
      delay: 125,
    });
    topRight
      .callFunction(function(state) {
        state.elements.cursor.style.display = 'none';
      })
      .typeString('since July 2020')
      .start();

    const center = new Typewriter(centerRef, {
      delay: 125,
    });
    center
      .callFunction(function(state) {
        state.elements.cursor.style.display = 'none';
      })
      .typeString('↳ Design and Technology practice run by Darek Nyckowiak → Designer and Software Engineer')
      .start();

    const bottom = new Typewriter(bottomRef, {
      delay: 125,
    });
    bottom
      .callFunction(function(state) {
        state.elements.cursor.style.display = 'none';
      })
      .typeString('Scroll for details ↴')
      .start();

  }, []);

  return (
    <div style={{position: 'absolute', height: '100vh'}}>
      <Container fluid style={{ width: '500px'}}>
        <Row align="start" justify="start" style={{ height: '30vh' }}>
          <Col sm={4}>
            <TextLarge
              ref={element => {
                topLeftRef = element;
              }}
            />
          </Col>
          <Col sm={4}>
          </Col>
          <Col sm={4}>
            <TextLarge
              ref={element => {
                topRightRef = element;
              }}
            />
          </Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '30vh' }}>
          <Col sm={12} >
          <Wrapper>
            <TitleLarge
              ref={element => {
                 titleRef = element;
               }}
            />
          </Wrapper>
          </Col>
        </Row>
        <Row align="end" justify="start" style={{ height: '10vh' }}>
          <Col sm={4}><TextLarge></TextLarge></Col>
          <Col sm={7}>
            <TextLarge
              ref={element => {
                centerRef = element;
              }}
            />
          </Col>
        </Row>

      </Container>

      <div style={{position: 'absolute', width: '430px', height: '55px', bottom: '0px', backgroundColor:'#F7F5EB', paddingLeft:'20px', paddingTop:'36px', marginBottom:'-2px'}}>
        <TextLargeClean
          style={{color:'#FF6700'}}
          ref={element => {
            bottomRef = element;
          }}
        />
      </div>
    </div>

  );
 };

export default AboutLarge;
