import styled from 'styled-components'


// Background
export const BackgroundLarge = styled.div`
  background-color: #EDEDED;
`
export const BackgroundMedium = styled.div`
  background-color: #1F2023;
`
export const BackgroundSmall = styled.div`
  background-color: #1F2023;
`

// Text
export const TextLarge = styled.p`
  font-size: 14px;
  color: #F6F6F6;
`
export const TextLargeClean = styled.p`
  font-size: 14px;
  color: #F6F6F6;
  margin-top: 0px;
`
export const TextMedium = styled.p`
  font-size: 13px;
  color: #F6F6F6;
`
export const TextMediumClean = styled.p`
  font-size: 13px;
  color: #F6F6F6;
  margin-top: 0px;
`
export const TextSmall = styled.p`
  font-size: 12px;
  color: #F6F6F6;
`

// Title
export const TitleLarge = styled.p`
  margin-top: 10px;
  font-family: 'reason01';
  font-size: 10vw;
  letter-spacing: -3px;
  line-height: 85%;
  color: #F6F6F6;
  user-select: none;
  transform: translate(0,110%);
`
export const TitleLargeB = styled.p`
  margin-top: 0px;
  font-family: 'reason01';
  font-size: 10vw;
  letter-spacing: -3px;
  line-height: 85%;
  color: #F6F6F6;
  user-select: none;
  transform: translate(0,110%);
`

export const TitleLarge2 = styled.p`
  margin: 0px;
  margin-top: 6vw;
  font-family: 'reason02';
  font-size: .8vw;
  letter-spacing: 0px;
  line-height: 45%;
  color: #F6F6F6;
`

export const TitleLarge3 = styled.p`
  display: inline-block;
  margin: 0px;
  font-family: 'reason03';
  font-size: 2.2vw;
  letter-spacing: 1px;
  line-height: 90%;
  color: #F6F6F6;
`

export const TitleLarge4 = styled.p`
  display: inline-block;
  margin: 0px;
  margin-right: 10px;
  font-family: 'reason04';
  font-size: 2.2vw;
  letter-spacing: 0px;
  line-height: 90%;
  color: #F6F6F6;
`

export const TitleLarge5 = styled.p`
  margin-top: 10px;

  margin-bottom: 2vw;
  font-family: 'reason05';
  font-size: .6vw;
  letter-spacing: 3px;
  line-height: 55%;
  color: #F6F6F6;
`

export const TitleLarge6 = styled.p`
  margin-top: 24px;
  font-family: 'reason01';
  font-size: 3.2vw;
  letter-spacing: 3px;
  line-height: 55%;
  color: #F6F6F6;
`

export const TitleMedium = styled.p`
  margin-top: 0px;
  font-size: 80px;
  letter-spacing: -1.6px;
  color: #F6F6F6;
`
export const TitleSmall = styled.p`
  margin-top: 0px;
  font-size: 65px;
  letter-spacing: -.6px;
  color: #F6F6F6;
`




export const TitleLarge2B = styled.p`
  margin: 0px;
  margin-top: 220px;
  font-family: 'reason02';
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 45%;
  color: #F6F6F6;
`

export const TitleLarge3B = styled.p`
  display: inline-block;
  margin: 0px;
  font-family: 'reason03';
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 90%;
  color: #F6F6F6;
`

export const TitleLarge4B = styled.p`
  display: inline-block;
  margin: 0px;
  margin-right: 10px;
  font-family: 'reason04';
  font-size: 28px;
  letter-spacing: 0px;
  line-height: 90%;
  color: #F6F6F6;
`

export const TitleLarge5B = styled.p`
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'reason05';
  font-size: 9.5px;
  letter-spacing: 3px;
  line-height: 55%;
  color: #F6F6F6;
`

export const TitleLarge6B = styled.p`
  margin-top: 30px;
  font-family: 'reason01';
  font-size: 50px;
  letter-spacing: 3px;
  line-height: 55%;
  color: #F6F6F6;
`

export const TitleLargeTop = styled.p`
  margin-top: 10px;
  font-family: 'reason01';
  font-size: 230px;
  letter-spacing: -3px;
  line-height: 85%;
  color: #F6F6F6;
  user-select: none;
  transform: translate(0,110%);
`

export const TitleLargeBottom = styled.p`
  margin-top: 0px;
  font-family: 'reason01';
  font-size: 230px;
  letter-spacing: -3px;
  line-height: 85%;
  color: #F6F6F6;
  user-select: none;
  transform: translate(0,120%);
`

export const WrapperBig = styled.div`
  overflow: hidden;
  height: 200px;
`




// Logo
export const LetterOne = styled.div`
  background: url(${require('../../../svg/1.svg')}) no-repeat;
  background-size: 100% 100%;
  background-position-y: top;
  width: 100%;
  height: 100%;
`
export const LetterTwo = styled.div`
  background: url(${require('../../../svg/2.svg')}) no-repeat;
  background-size: 100% 100%;
  background-position-y: top;
  width: 100%;
  height: 100%;
`
export const LetterThree = styled.div`
  background: url(${require('../../../svg/3.svg')}) no-repeat;
  background-size: 100% 100%;
  background-position-y: top;
  width: 100%;
  height: 100%;
`
export const LogoSmall = styled.div`
  background: url(${require('../../../svg/logo.svg')}) no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
`
export const LogoTiny = styled.div`
  background: url(${require('../../../svg/logo.svg')}) no-repeat;
  width: 50px;
  height: 20px;
  background-size: 45px auto;
`



const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`

export const Wrapper = styled.div`
  overflow: hidden;
  height: 9vw;
`

const Thing = styled.button`
  color: blue;

  ::before {
    content: '🚀';
  }

  :hover {
    color: red;
  }
`


export const BigHeadlineOne = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #fff;
  line-height: 75%;
  user-select: none;
`

export const BigHeadlineTwo = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #fff;
  margin-left: 34vh;
  line-height: 75%;
  user-select: none;
`

export const BigHeadlineTwoMargin = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #fff;
  line-height: 75%;
  user-select: none;
  display: inline-block;
`

export const BigHeadlineThree = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #fff;
  line-height: 75%;
  user-select: none;
`

export const BigHeadlineWrapper = styled.div`
  position: absolute;
  margin-top: 2vh;
  left: 1vh;
`

export const BigHeadlineWrapperMargin = styled.div`
  position: absolute;
  margin-top: 2vh;
  margin-left: -5vh;
  left: 1vh;
`

export const AdTextWrapper = styled.div`
  margin-top: 2vh;
  margin-left: 1vh;
  left: 1vh;
  display: inline-block;
`






export const Module03HeadlineOne = styled.div`
  font-family: 'reason01';
  text-align: center;
  font-size: 16vh;
  color: #fff;
  line-height: 80%;
  user-select: none;
`

export const Module03TextWrapper = styled.div`
  left: 1vh;
  margin: auto;
  width: 100vw;
  position: absolute;
`

export const Module03Photo = styled.div`
  width: 22vw;
  height: 30vw;
  background-color: #BEAB7A;
  margin-top: 5vh;
  margin-left: 50vw;
  transform: translate(-11vw, 3vw);
  line-height: 75%;
  position: absolute;
  user-select: none;
`

export const Module03HeadlineThree = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #fff;
  line-height: 75%;
  user-select: none;
`

export const Module03HeadlineWrapper = styled.div`
  display: block;
  margin: auto;
  margin-bottom: 7vh;
  text-align: center;
`

export const Module03Wrapper = styled.div`
  left: 1vh;
  margin: auto;
  width: 100vw;
  display: inline-block;
`





export const Module06HeadlineOne = styled.div`
  font-family: 'reason01';
  font-size: 16vh;
  color: #fff;
  line-height: 80%;
  user-select: none;
`

export const Module06TextWrapper = styled.div`
  left: 1vh;
  margin: auto;
  width: 100vw;
  position: absolute;
  margin-left: 1vw;
`

export const Module06Photo = styled.div`
  width: 34vh;
  height: 50vh;
  background-color: #BEAB7A;
  margin-top: 5vh;
  margin-left: 50vw;
  transform: translate(-17vh, 3vh);
  line-height: 75%;
  position: absolute;
  user-select: none;
`

export const Module06HeadlineThree = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #fff;
  line-height: 75%;
  user-select: none;
`

export const Module06HeadlineWrapper = styled.div`
  display: block;
  margin-bottom: 7vh;
  margin-top: 25vh;
`

export const Module06Wrapper = styled.div`
  left: 1vh;
  margin: auto;
  width: 100vw;
  display: inline-block;
`






export const Module05HeadlineOne = styled.div`
  font-family: 'reason01';
  font-size: 16vw;
  color: #fff;
  user-select: none;
  transform: rotate(90deg) translateX(0px);
  transform-origin: 0px 0px;
  margin-left: 5vw;
`

export const Module05TextWrapper = styled.div`
  left: 1vh;
  margin: auto;
  width: 100vw;
  position: absolute;
  margin-left: 1vw;
`

export const Module05Photo = styled.div`
  width: 34vh;
  height: 50vh;
  background-color: #BEAB7A;
  margin-top: 5vh;
  margin-left: 50vw;
  transform: translate(-17vh, 3vh);
  line-height: 75%;
  position: absolute;
  user-select: none;
`

export const Module05HeadlineThree = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #fff;
  line-height: 75%;
  user-select: none;
`

export const Module05HeadlineWrapper = styled.div`
  display: block;
  margin-bottom: 7vh;
  margin-top: 25vh;
`

export const Module05Wrapper = styled.div`
  left: 1vh;
  margin: auto;
  width: 100vw;
  display: inline-block;
`

export const TitleLarge2s = styled.p`
  margin: 0px;
  margin-top: 20px;
  font-family: 'reason02';
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 45%;
  color: #F6F6F6;
  user-select: none;
`

export const TitleLarge3s = styled.p`
  display: inline-block;
  margin: 0px;
  font-family: 'reason03';
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 90%;
  color: #F6F6F6;
  user-select: none;
`

export const TitleLarge4s = styled.p`
  display: inline-block;
  margin: 0px;
  margin-right: 10px;
  font-family: 'reason04';
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 90%;
  color: #F6F6F6;
  user-select: none;
`

export const TitleLarge5s = styled.p`
  margin-top: 10px;
  margin-bottom: 18px;
  font-family: 'reason05';
  font-size: 7px;
  letter-spacing: 3px;
  line-height: 55%;
  color: #F6F6F6;
  user-select: none;
`
export const TitleLarge6s = styled.p`
  margin-top: 24px;
  font-family: 'reason01';
  font-size: 55px;
  letter-spacing: 3px;
  line-height: 55%;
  color: #F6F6F6;
  user-select: none;
`
