import { Vector3 } from 'three';

export default {
  plane(width, height) {
    return (u, v, t) => {
      const x = (u - 0.5) * width;
      const y = (v + 0.5) * height;
      const z = 0;

      return new Vector3(x, y, z);
    };
  },
};


/*
import { Vector3 } from 'three';


export default {


  plane(width, height) {
    return (u, v, target) => {
      let x = (u - 0.5) * width;
      let y = (v + 0.5) * height;
      let z = 0;

      //console.log(x, y, z);

      return new Vector3(x, y, z);
    };
  },
};



plane(width, height, 1) {
  return (u, v, target) => {
    let x = (u - 0.5) * width;
    let y = (v + 0.5) * height;
    let z = 0;

    return new THREE.Vector3(x, y, z);
  };

  */
