import React from 'react';
import MediaQuery from 'react-responsive';
import AboutLarge from './About/AboutLarge'
import AboutMedium from './About/AboutMedium'
import AboutSmall from './About/AboutSmall'



const Breakpoints = { large: 1200, medium: 900, small: 500 }

const About = () => (

    <div>

        {/* LARGE

        <MediaQuery minWidth={1200}>*/}
            <AboutLarge/>
        {/*</MediaQuery>



         MEDIUM
        <MediaQuery minWidth={801} maxWidth={1199}>
            <AboutMedium/>
        </MediaQuery>

        {/* SMALL

        <MediaQuery minWidth={1} maxWidth={800}>
            <AboutSmall/>
        </MediaQuery>
        */}

    </div>
)

export default About;
