import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { useInView } from 'react-hook-inview'
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'

import { Wrapper } from './module_07.style';
import { Module06Wrapper } from './module_07.style'
import { Module06HeadlineOne } from './module_07.style'
import { Module06HeadlineOne2 } from './module_07.style'
import { Module06HeadlineOne3 } from './module_07.style'
import { Module06HeadlineOne4 } from './module_07.style'
import { Module06HeadlineOne5 } from './module_07.style'
import { Module06HeadlineOne6 } from './module_07.style'
import { Module06HeadlineOne7 } from './module_07.style'
import { Module06HeadlineOne8 } from './module_07.style'
import { Module06HeadlineOne9 } from './module_07.style'
import { Module06Line1 } from './module_07.style'
import { Module06Line2 } from './module_07.style'
import { Module06Line3 } from './module_07.style'
import { Module06Line4 } from './module_07.style'
import { Module06Line5 } from './module_07.style'
import { Module06Line6 } from './module_07.style'
import { Module06HeadlineTwo } from './module_07.style'
import { Module06HeadlineThree } from './module_07.style'
import { Module06HeadlineWrapper } from './module_07.style'
import { Module06Photo } from './module_07.style'
import { Module06TextWrapper } from './module_07.style'
import { Module06TextWrapperFull } from './module_07.style'

import { TitleLarge2 } from './module_07.style';
import { TitleLarge3 } from './module_07.style';
import { TitleLarge4 } from './module_07.style';
import { TitleLarge5 } from './module_07.style';
import { BackgroundLarge } from './module_07.style';

import { TitleLarge2B } from './module_07.style';
import { TitleLarge3B } from './module_07.style'
import { TitleLarge4B } from './module_07.style'
import { TitleLarge5B } from './module_07.style'


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {


  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.2
  })
  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne2.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.8
  })
  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne3.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:1.4
  })


  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne4.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.4
  })
  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne5.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:1
  })
  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne6.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:1.6
  })


  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne7.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.6
  })
  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne8.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:1.2
  })
  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne9.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:1.8
  })


  TweenMax.to(
    document.querySelector( String("."+Module06Line1.styledComponentId) ),
    1.2,
  {
    width:"24vw",
    ease:Expo.easeOut,
    delay:.3
  })
  TweenMax.to(
    document.querySelector( String("."+Module06Line2.styledComponentId) ),
    1.2,
  {
    width:"24vw",
    ease:Expo.easeOut,
    delay:.9
  })
  TweenMax.to(
    document.querySelector( String("."+Module06Line3.styledComponentId) ),
    1.2,
  {
    width:"24vw",
    ease:Expo.easeOut,
    delay:1.5
  })
  TweenMax.to(
    document.querySelector( String("."+Module06Line4.styledComponentId) ),
    1.2,
  {
    width:"24vw",
    ease:Expo.easeOut,
    delay:.5
  })
  TweenMax.to(
    document.querySelector( String("."+Module06Line5.styledComponentId) ),
    1.2,
  {
    width:"24vw",
    ease:Expo.easeOut,
    delay:1.1
  })
  TweenMax.to(
    document.querySelector( String("."+Module06Line6.styledComponentId) ),
    1.2,
  {
    width:"24vw",
    ease:Expo.easeOut,
    delay:1.7
  })


  TweenMax.to(
    document.querySelector( String("."+Module06Photo.styledComponentId) ),
    5,
  {
    padding:"4vw",
    ease:Expo.easeOut,
    delay:0
  })
  TweenMax.to(
    document.querySelector( String("."+Module06Photo.styledComponentId) ),
    5,
  {
    x:"-14vw",
    ease:Expo.easeOut,
    delay:0
  })
  TweenMax.to(
    document.querySelector( String("."+Module06Photo.styledComponentId) ),
    5,
  {
    y:"-3.5vw",
    ease:Expo.easeOut,
    delay:0
  })

}

const Module07   = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (


  <div>

    {/* LARGE */}

    <MediaQuery minWidth={1200}>
      <BackgroundLarge>
        <Container fluid>
          <Row align="start" justify="start" style={{ height: '14vw' }}>
            <div></div>
          </Row>

          <div ref={ref}>
          {isVisible
            ? handleWaypointEnter()
            : ''
          }
          </div>

          <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
            <Col sm={12}>
              <Module06Wrapper>
                <Module06Photo></Module06Photo>
                <Module06TextWrapper style={{ marginLeft: '20vw'}}>
                  <Module06Line1/>
                  <Wrapper><Module06HeadlineOne>R</Module06HeadlineOne></Wrapper>
                  <Module06Line2/>
                  <Wrapper><Module06HeadlineOne2>S</Module06HeadlineOne2></Wrapper>
                  <Module06Line3/>
                  <Wrapper><Module06HeadlineOne3>F</Module06HeadlineOne3></Wrapper>
                </Module06TextWrapper>
                <Module06TextWrapper style={{ marginLeft: '50vw'}}>
                  <Module06Line4/>
                  <Wrapper><Module06HeadlineOne4>E</Module06HeadlineOne4></Wrapper>
                  <Module06Line5/>
                  <Wrapper><Module06HeadlineOne5>O</Module06HeadlineOne5></Wrapper>
                  <Module06Line6/>
                  <Wrapper><Module06HeadlineOne6>O</Module06HeadlineOne6></Wrapper>
                </Module06TextWrapper>
                <Module06TextWrapper style={{ marginLeft: '80vw'}}>
                  <Wrapper><Module06HeadlineOne7>A</Module06HeadlineOne7></Wrapper>
                  <Wrapper><Module06HeadlineOne8>N</Module06HeadlineOne8></Wrapper>
                  <Wrapper><Module06HeadlineOne9>R</Module06HeadlineOne9></Wrapper>
                </Module06TextWrapper>
              </Module06Wrapper>
            </Col>
          </Row>
        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* MEDIUM */}

    <MediaQuery minWidth={801} maxWidth={1199}>
      <BackgroundLarge>
        <Container fluid>
          <Row align="start" justify="start" style={{ height: '14vw' }}>
            <div></div>
          </Row>

          <div ref={ref}>
          {isVisible
            ? handleWaypointEnter()
            : ''
          }
          </div>

          <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
            <Col sm={12}>
              <Module06Wrapper>
                <Module06Photo></Module06Photo>
                <Module06TextWrapper style={{ marginLeft: '20vw'}}>
                  <Module06Line1/>
                  <Wrapper><Module06HeadlineOne>R</Module06HeadlineOne></Wrapper>
                  <Module06Line2/>
                  <Wrapper><Module06HeadlineOne2>E</Module06HeadlineOne2></Wrapper>
                  <Module06Line3/>
                  <Wrapper><Module06HeadlineOne3>A</Module06HeadlineOne3></Wrapper>
                </Module06TextWrapper>
                <Module06TextWrapper style={{ marginLeft: '50vw'}}>
                  <Module06Line4/>
                  <Wrapper><Module06HeadlineOne4>E</Module06HeadlineOne4></Wrapper>
                  <Module06Line5/>
                  <Wrapper><Module06HeadlineOne5>O</Module06HeadlineOne5></Wrapper>
                  <Module06Line6/>
                  <Wrapper><Module06HeadlineOne6>O</Module06HeadlineOne6></Wrapper>
                </Module06TextWrapper>
                <Module06TextWrapper style={{ marginLeft: '80vw'}}>
                  <Wrapper><Module06HeadlineOne7>A</Module06HeadlineOne7></Wrapper>
                  <Wrapper><Module06HeadlineOne8>N</Module06HeadlineOne8></Wrapper>
                  <Wrapper><Module06HeadlineOne9>R</Module06HeadlineOne9></Wrapper>
                </Module06TextWrapper>
              </Module06Wrapper>
            </Col>
          </Row>
        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* SMALL */}

    <MediaQuery minWidth={1} maxWidth={800}>
      <BackgroundLarge>
        <Container fluid>
          <Row align="start" justify="start" style={{ height: '14vw' }}>
            <div></div>
          </Row>

          <div ref={ref}>
          {isVisible
            ? handleWaypointEnter()
            : ''
          }
          </div>

          <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
            <Col sm={12}>
              <Module06Wrapper>
                <Module06Photo></Module06Photo>
                <Module06TextWrapper style={{ marginLeft: '20vw'}}>
                  <Module06Line1/>
                  <Wrapper><Module06HeadlineOne>R</Module06HeadlineOne></Wrapper>
                  <Module06Line2/>
                  <Wrapper><Module06HeadlineOne2>E</Module06HeadlineOne2></Wrapper>
                  <Module06Line3/>
                  <Wrapper><Module06HeadlineOne3>A</Module06HeadlineOne3></Wrapper>
                </Module06TextWrapper>
                <Module06TextWrapper style={{ marginLeft: '50vw'}}>
                  <Module06Line4/>
                  <Wrapper><Module06HeadlineOne4>E</Module06HeadlineOne4></Wrapper>
                  <Module06Line5/>
                  <Wrapper><Module06HeadlineOne5>O</Module06HeadlineOne5></Wrapper>
                  <Module06Line6/>
                  <Wrapper><Module06HeadlineOne6>O</Module06HeadlineOne6></Wrapper>
                </Module06TextWrapper>
                <Module06TextWrapper style={{ marginLeft: '80vw'}}>
                  <Wrapper><Module06HeadlineOne7>A</Module06HeadlineOne7></Wrapper>
                  <Wrapper><Module06HeadlineOne8>N</Module06HeadlineOne8></Wrapper>
                  <Wrapper><Module06HeadlineOne9>R</Module06HeadlineOne9></Wrapper>
                </Module06TextWrapper>
              </Module06Wrapper>
            </Col>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

  </div>
)
}

export default Module07;
