import React, { Component } from 'react'
import { Howl } from 'howler'
import Home from '../layouts/home/ProjectGrid'
import About from '../layouts/content/About'

import MatterScene from '../layouts/matter/MatterScene'
import Three from '../layouts/three/ThreeScene'
import Three2 from '../layouts/three2/ThreeScene'

import sound_01 from '../sounds/pik2.mp3'

import { TweenMax, TimelineMax, CSSPlugin, Expo, Linear } from 'gsap/all'

let iterator = 0;

class HomeComponent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.querySelector('.content').style.overflowY = "hidden"
    window.dispatchEvent(new Event('resize'));
  }

  addNumber() {
    console.log(this.sound)
    this.sound.play()

    iterator++
    if(iterator > 2) iterator = 0
  }

  render() {
    return (
      <>
        <Home />
        <Three />
        <About />
      </>
    )
  }
}

export default HomeComponent;
