import React, { Component } from 'react'
import Project from '../layouts/project/project'


class ProjectComponent extends Component {

  componentDidMount() {

    document.querySelector('.content').style.overflowY = "scroll"
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    return (
      <Project
        id={this.props.match.params.id}
      />
    )
  }
}

export default ProjectComponent;
