import React, { Component } from 'react'
import Works from '../layouts/works/Works'


class WorksComponent extends Component {

  componentDidMount() {
    
    document.querySelector('.content').style.overflowY = "scroll"
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    return (
      <Works/>
    )
  }
}

export default WorksComponent;
