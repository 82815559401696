import styled from 'styled-components'


// Background
export const BackgroundLarge = styled.div`
  background-color: #F7F5EB;
`
export const BackgroundMedium = styled.div`
  background-color: #F7F5EB;
`
export const BackgroundSmall = styled.div`
  background-color: #F7F5EB;
`

// Text
export const TextXLarge = styled.p`
  font-size: 18px;
  color: #FF6700;
`
export const TextLarge = styled.p`
  font-size: 14px;
  color: #FF6700;
`
export const TextLargeXClean = styled.p`
  font-size: 14px;
  color: #FF6700;
  margin-top: 0px;
`
export const TextLargeClean = styled.p`
  font-size: 14px;
  color: #FF6700;
  margin-top: 0px;
`
export const TextMedium = styled.p`
  font-size: 13px;
  color: #FF6700;
`
export const TextMediumClean = styled.p`
  font-size: 13px;
  color: #FF6700;
  margin-top: 0px;
`
export const TextSmall = styled.p`
  font-size: 12px;
  color: #FF6700;
`

// Title
export const TitleLarge = styled.p`
  margin-top: 0px;
  font-size: 110px;
  letter-spacing: -3px;
  color: #FF6700;
`
export const TitleMedium = styled.p`
  margin-top: 0px;
  font-size: 80px;
  letter-spacing: -1.6px;
  color: #FF6700;
`
export const TitleSmall = styled.p`
  margin-top: 0px;
  font-size: 58px;
  letter-spacing: -.6px;
  color: #FF6700;
`

// Number
export const NumberLarge = styled.p`
  margin-top: 0px;
  font-size: 240px;
  letter-spacing: -8px;
  color: #FF6700;
  line-height: 97%;
  user-select: none;
`
export const NumberMedium = styled.p`
  margin-top: 0px;
  font-size: 200px;
  letter-spacing: -1.6px;
  color: #FF6700;
  user-select: none;
`
export const NumberSmall = styled.p`
  margin-top: 0px;
  font-size: 178px;
  letter-spacing: -.6px;
  color: #FF6700;
  user-select: none;
`

// Headline
export const HeadlineXLarge = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 60px;
  letter-spacing: -1px;
  color: #FF6700;
  line-height: 105%;
`

export const HeadlineXLargeBottom = styled.div`
  margin-top: auto;
  -webkit-align-self: end;
  align-self: end;
  margin-bottom: 0px;
  font-size: 60px;
  letter-spacing: -1px;
  color: #FF6700;
  line-height: 70%;
`

export const HeadlineLarge = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 50px;
  letter-spacing: -1px;
  color: #FF6700;
  line-height: 95%;
`

export const HeadlineLargeBottom = styled.div`
  margin-top: auto;
  -webkit-align-self: end;
  align-self: end;
  margin-bottom: 0px;
  font-size: 50px;
  letter-spacing: -1px;
  color: #FF6700;
  line-height: 95%;
`

export const HeadlineMedium = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 40px;
  letter-spacing: -1.6px;
  color: #FF6700;
`
export const HeadlineSmall = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 40px;
  letter-spacing: -.6px;
  color: #FF6700;
  line-height: 98%;
`
export const HeadlineSmallBottom = styled.div`
  margin-top: auto;
  -webkit-align-self: end;
  align-self: end;
  margin-bottom: 0px;
  font-size: 40px;
  letter-spacing: -1px;
  color: #FF6700;
  line-height: 98%;
`

// Logo
export const LetterOne = styled.div`
  background: url(${require('../../../svg/1.svg')}) no-repeat;
  background-size: 100% 100%;
  background-position-y: top;
  width: 100%;
  height: 100%;
`
export const LetterTwo = styled.div`
  background: url(${require('../../../svg/2.svg')}) no-repeat;
  background-size: 100% 100%;
  background-position-y: top;
  width: 100%;
  height: 100%;
`
export const LetterThree = styled.div`
  background: url(${require('../../../svg/3.svg')}) no-repeat;
  background-size: 100% 100%;
  background-position-y: top;
  width: 100%;
  height: 100%;
`
export const LogoSmall = styled.div`
  background: url(${require('../../../svg/logo.svg')}) no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
`
export const LogoTiny = styled.div`
  background: url(${require('../../../svg/logo.svg')}) no-repeat;
  width: 50px;
  height: 20px;
  background-size: 45px auto;
`



const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`

const Wrapper = styled.section`
  padding: 4em;
  background: papayawhip;
`

const Thing = styled.button`
  color: blue;

  ::before {
    content: '🚀';
  }

  :hover {
    color: red;
  }
`
