import React, { Component } from 'react'
import * as THREE from 'three'
import { Clock } from "three";
import GLTFLoader from 'three-gltf-loader'
import OrbitControls from 'three-orbitcontrols'
import { TweenMax, TimelineMax, Expo } from 'gsap/all'




import ViewNavigation from './viewnavigation/ViewNavigation'
import Headline from './headline/Headline'
import Grain from './grain/Grain'

import flag from '../../images/bg.png'

const Items = []
const Positions = []
const S = 30

let CurrentView = 0
let CurrentSelection
let AnimatedCamera = true

var mouse = new THREE.Vector2(), INTERSECTED;

var iterator = 0


class ThreeScene extends Component {

   componentDidMount() {

      this.intervalId = setInterval(this.switchTo, 5000);

      const width = this.mount.clientWidth
      const height = this.mount.clientHeight

      this.mouse = new THREE.Vector2()

      // ADD SCENE
      this.scene = new THREE.Scene()

      // ADD CAMERA
      this.camera = new THREE.PerspectiveCamera(
        35,
        width / height,
        0.1,
        10000
      )

      // CAMERA START POSITION
      this.camera.position.z = 70
      //TweenMax.to(this.camera.position, 5, {z: 25, ease: Expo.easeOut, delay: 2.3})


      // ADD RENDERER
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      this.renderer.setClearColor('#FF6700', 0)
      this.renderer.setSize(width, height)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.mount.appendChild(this.renderer.domElement)

      this.clock = new Clock()

      this.textureText = new THREE.TextureLoader().load(flag);
      this.textureText.wrapS = THREE.RepeatWrapping;
      this.textureText.wrapT = THREE.RepeatWrapping;
      this.textureText.repeat.set( 20, 20 );

      this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
      this.geometry = new THREE.TorusKnotBufferGeometry( 18, 8, 250, 50 );

      this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );
      this.scene.add( this.torusMesh );


      //this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.start()

      window.addEventListener(
      'resize',
      () => {
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(window.innerWidth, window.innerHeight)
      },
      false)
   }

   switchTo = () => {
     this.scene.remove( this.torusMesh )

     iterator++
     if(iterator > 1) iterator = 0

     if(iterator == 0) {
       this.textureText.repeat.set( 20, 20 );
       //textureText.repeat.y = §;

       this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
       this.geometry = new THREE.TorusKnotBufferGeometry( 18, 8, 250, 50 );

       this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );
       this.scene.add( this.torusMesh );
     }
     if(iterator == 1) {
       this.textureText.repeat.set( 33.5, 76.5 );
       this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
       this.geometry = new THREE.PlaneBufferGeometry( 420, 200, 1440 );

       this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );
       this.torusMesh.rotation.x = THREE.Math.degToRad(-20)
       this.torusMesh.rotation.z = THREE.Math.degToRad(20)
       this.torusMesh.position.x = 100
       this.torusMesh.position.z = 20

       this.scene.add( this.torusMesh );
      }
      if(iterator == 2) {
        this.textureText.repeat.set( 33.5, 76.5 );
        this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
        this.geometry = new THREE.PlaneBufferGeometry( 420, 200, 1440 );

        this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );
        this.torusMesh.rotation.x = THREE.Math.degToRad(-20)
        this.torusMesh.rotation.z = THREE.Math.degToRad(20)
        this.torusMesh.position.x = 100
        this.torusMesh.position.z = 20
        this.scene.add( this.torusMesh );

        this.twist( this.geometry )

       }
   }

   componentWillUnmount() {
      window.removeEventListener('resize', null)
      this.stop()
      this.renderPass = null;
      this.effectPass = null;
      this.composer = null;
      this.camera = null;
      this.textureText = null;
      this.torusMesh = null;
      this.scene = null;
      this.mount.removeChild(this.renderer.domElement)
      this.renderer = null;
   }

   twist = (geometry) => {
      const quaternion = new THREE.Quaternion();

      for (let i = 0; i < geometry.vertices.length; i++) {
        // a single vertex Y position
        const yPos = geometry.vertices[i].y;
        const twistAmount = 10;
        const upVec = new THREE.Vector3(0, 1, 0);

        quaternion.setFromAxisAngle(
          upVec,
          (Math.PI / 180) * (yPos / twistAmount)
        );

        geometry.vertices[i].applyQuaternion(quaternion);
      }

      // tells Three.js to re-render this mesh
      geometry.verticesNeedUpdate = true;
    }

   wavesBuffer = ( waveSize, magnitude ) => {
      const theTime =  performance.now() * .001;
      var pos = this.torusMesh.geometry.attributes.position;
      let center = new THREE.Vector3(0,0,0);
      var vec3 = new THREE.Vector3(); // re-use

      for ( var i = 0, l = pos.count; i < l; i ++ ) {
        vec3.fromBufferAttribute(pos, i);
    		vec3.sub(center);
        var z = Math.sin( vec3.length() /- waveSize + (theTime)) * magnitude;
        pos.setZ(i, z);
      }
      pos.needsUpdate = true
   }

   start = () => {
      if (!this.frameId) {
        this.frameId = requestAnimationFrame(this.animate)
      }
   }

   stop = () => {
     cancelAnimationFrame(this.frameId)
   }

   animate = () => {


     if(iterator == 0) {

       var rotSpeed = .002

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = x * Math.cos(rotSpeed) + z * Math.sin(rotSpeed)
           this.camera.position.y = x * Math.cos(rotSpeed) + z * Math.sin(rotSpeed)
           this.camera.position.z = z * Math.cos(rotSpeed) - x * Math.sin(rotSpeed)

           this.textureText.offset.y += 0.03
     }
     if(iterator == 1) {

       var rotSpeed = .002

       this.wavesBuffer( 2.8 , 6);

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 70

           this.textureText.offset.y += 0.03
     }
     if(iterator == 2) {

       var rotSpeed = .002
       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 70

           this.textureText.offset.y += 0.03
     }

     this.camera.lookAt(new THREE.Vector3(0,0,0))
     this.renderScene()
     this.frameId = window.requestAnimationFrame(this.animate)
   }

   renderScene = () => {
     this.renderer.render(this.scene, this.camera)
   }

   render(){
     return(
       <div
         style={{
           width: '100vw',
           height: '100vh',
           display: 'flex',
           backgroundColor: '#FF6700'
         }}
         ref={(mount) => { this.mount = mount }}
       >

       </div>

     )
   }
}
export default ThreeScene
