import React from "react";
import ReactDOM from "react-dom";
import Matter from "matter-js";

class MatterScene extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    function resizeCanvas() {

    }

    window.addEventListener('resize', resizeCanvas, false);

    var w = document.documentElement.clientWidth * window.devicePixelRatio;
    var h = document.documentElement.clientHeight * window.devicePixelRatio;


    var World = Matter.World,
      Bodies = Matter.Bodies,
      Mouse = Matter.Mouse,
      MouseConstraint = Matter.MouseConstraint;

    this.engine = Matter.Engine;
    this.render = Matter.Render;

    var engine = this.engine.create({

    });

    var render = this.render.create({
      element: this.refs.scene,
      engine: engine,
      options: {
        width: w,
        height: h,
        wireframes: false,
        hasBounds: true,
        showSleeping: true,
        background: '#FF6700'
      }
    });

    var ballA = Bodies.circle(w/4, 100, 200, { restitution: .9, render: { fillStyle: '#EDEDED' }});
    var ballB = Bodies.circle(w/3, 150, 200, { restitution: .9, render: { fillStyle: '#EDEDED' } });
    var ballC = Bodies.circle(w/2, 250, 200, { restitution: .9, render: { fillStyle: '#EDEDED' } });
    var ballD = Bodies.circle(w/3, 300, 200, { restitution: .9, render: { fillStyle: '#EDEDED' } });
    var ballE = Bodies.circle(w/4, 350, 200, { restitution: .9, render: { fillStyle: '#EDEDED' } });
    World.add(engine.world, [
      // walls
      Bodies.rectangle(w/2, 0, w, 10, { isStatic: true, render: { fillStyle: '#FF6700' } }),
      Bodies.rectangle(w/2, h, w, 10, { isStatic: true, render: { fillStyle: '#FF6700' } }),
      Bodies.rectangle(w, h/2, 10, h, { isStatic: true, render: { fillStyle: '#FF6700' } }),
      Bodies.rectangle(0,  h/2, w/1.8, h, { isStatic: true, render: { fillStyle: '#FF6700' } })
    ]);

    World.add(engine.world, [ballA, ballB, ballC, ballD, ballE]);

    // add mouse control
    var mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: true
          }
        }
      });

    World.add(engine.world, mouseConstraint);

    Matter.Events.on(mouseConstraint, "mousedown", function(event) {
      World.add(engine.world, Bodies.circle(w/2, 50, 200, { restitution: .9, render: { fillStyle: '#EDEDED' } }));
    });

    this.engine.run(engine);

    this.render.run(render);
  }

  componentWillUnmount() {
     window.removeEventListener('resize', null)
/*
     Engine = null
     this.render = null
     World = null
     Bodies = null
     Mouse = null
     MouseConstraint = null
*/
    this.render.textures = {};
    this.render.context = null;
    this.render.canvas = null;
    this.engine = null;
    this.render = null;

    //this.render.canvas.remove();
    //this.render.canvas = null;
    //this.render.context = null;
    //this.render.textures = {};




  }

  render() {
    return <div style={{ display: 'flex' }} ref="scene" />;
  }
}
export default MatterScene;
