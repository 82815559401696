import React from 'react';
import Module01 from '../module_01/module_01';
import Module02a from '../module_02a/module_02';
import Module02b from '../module_02b/module_02';
import Module02c from '../module_02c/module_02';
import Module02d from '../module_02d/module_02';
import Module02e from '../module_02e/module_02';
import Module03 from '../module_03/module_03';
import Module04 from '../module_04/module_04';
import Module05 from '../module_05/module_05';
import Module06 from '../module_06/module_06';
import Module07 from '../module_07/module_07';

const AboutLarge = () => (

  <div>
    <Module02a/>
    <Module02b/>
    <Module02c/>
    <Module02d/>
    <Module02e/>
    <Module03/>
    <Module04/>
    <Module05/>
    <Module06/>
    <Module07/>
  </div>

)

export default AboutLarge;
