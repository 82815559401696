import React from 'react';
import { Link } from 'react-router-dom'

function HeaderComponent (){
  return (
    <ul>
      <li>

      </li>
      <li>
        <Link to="/about"> About </Link>
      </li>
      <li>
        <Link to="/team"> Case </Link>
      </li>
    </ul>
  )
}

export default HeaderComponent;
