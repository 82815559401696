import styled from 'styled-components'

export const BackgroundLarge = styled.div`
  background-color: #F6F6F6;
`

export const HeadlineOne = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #1F2023;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const HeadlineTwo = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #1F2023;
  margin-left: 34vh;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const HeadlineThree = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #1F2023;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const Wrapper = styled.div`
  overflow: hidden;
`

export const HeadlineWrapper = styled.div`
  position: absolute;
  margin-top: 2vh;
  left: 1vh;
`

export const Line = styled.div`
  width: 100%;
  height: 10px;
  margin-top: 19px;
  border-top: solid .8px #1F2023;
  opacity: .15;
`
// Text
export const TextLarge = styled.p`
  font-size: 14px;
  color: #1F2023;
`
