import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { BackgroundSmall } from './About.style';
import { TextLarge } from './About.style';
import { TextLargeClean } from './About.style';
import { TitleLarge } from './About.style';
import { LetterOne } from './About.style';
import { LetterTwo } from './About.style';
import { LetterThree } from './About.style';
import { LogoTiny } from './About.style';
import { NumberSmall } from './About.style';
import { HeadlineSmall } from './About.style';
import { HeadlineSmallBottom } from './About.style';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const AboutSmall = () => (

      <BackgroundSmall>
        <Container fluid>
          <Row align="start" justify="start" style={{ height: '3vw' }}>
            <Col sm={6}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '26.66vw' }}>
            <Col sm={6}></Col>
            <Col sm={6}><NumberSmall>1</NumberSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={12} style={{ height: '50vw', display: 'flex' }}><HeadlineSmallBottom>A perfect symbiosis of</HeadlineSmallBottom></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={12}><HeadlineSmall>design and technology fueling<br/>the future of your brand</HeadlineSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>

          <Row align="start" justify="start" style={{ height: '10vw' }}>
            <Col sm={2}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '26.66vw' }}>
            <Col sm={6}><NumberSmall>2</NumberSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '30vw' }}>
            <Col sm={12}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={12}><HeadlineSmall>Multidisciplinary capabilities</HeadlineSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>

          <Row align="start" justify="start" style={{ height: '10vw' }}>
            <Col sm={2}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '26.66vw' }}>
            <Col sm={6}></Col>
            <Col sm={6}><NumberSmall>3</NumberSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '30vw' }}>
            <Col sm={2}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={12}><HeadlineSmall>Continuous colaboration<br></br>processes</HeadlineSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '20vw' }}>
            <Col sm={2}></Col>
          </Row>

          <div style={{ backgroundColor:'#1F1F1F', padding:'15px', margin:'-15px'}}>
          <Row align="start" justify="start" style={{ height: '10vw' }}>
            <Col sm={2}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '26.66vw' }}>
            <Col sm={6}><NumberSmall style={{color:'#F7F5EB'}}>4</NumberSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '30vw' }}>
            <Col sm={2}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '20vw' }}>
            <Col sm={12}><HeadlineSmall style={{color:'#F7F5EB'}}>Contact Us</HeadlineSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '20vw' }}>
            <Col sm={2}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '30vw' }}>
            <Col sm={6}><TextLarge style={{color:'#F7F5EB'}}>Contact Us</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge style={{color:'#F7F5EB'}}><a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>info@typed.systems</a></TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '30vw' }}>
            <Col sm={6}><TextLarge style={{color:'#F7F5EB'}}>Work with us</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}>
              <TextLarge style={{color:'#F7F5EB'}}>
              For new business enquiries contact<br/>
              <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Darek Nyckowiak</a>
              </TextLarge>
            </Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '40vw' }}>
            <Col sm={6}><TextLarge style={{color:'#F7F5EB'}}>Folllow Us</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}>
              <TextLarge style={{color:'#F7F5EB'}}>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Instagram</a><br/>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Twitter</a><br/>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Facebook</a><br/>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>LinkedIn</a><br/>
              </TextLarge>
            </Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge style={{color:'#F7F5EB'}}>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge style={{color:'#F7F5EB'}}>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '10vw' }}>
            <Col sm={2}></Col>
          </Row>
          </div>
        </Container>
      </BackgroundSmall>

)

export default AboutSmall;
