import React, { Component } from 'react'
import About from '../layouts/about/About'


class AboutComponent extends Component {

  componentDidMount() {

    document.querySelector('.content').style.overflowY = "scroll"
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    return (
      <About/>
    )
  }
}

export default AboutComponent;
