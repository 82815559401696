import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components'

createGlobalStyle`
  @font-face {
    font-family: 'reason01';
    src: url(${require('../../../fonts/reason_01.woff')});
  }
`

export const HeadlineOne = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #F6F6F6;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const HeadlineTwo = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #F6F6F6;
  margin-left: 34vh;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const HeadlineThree = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #F6F6F6;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const Wrapper = styled.div`
  overflow: hidden;
`

export const HeadlineWrapper = styled.div`
  position: absolute;
  margin-top: 2vh;
  left: 1vh;
`
