import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import Navigation from './navigation/Navigation'
import HomeComponent from './pages/HomeComponent';
import ReelComponent from './pages/ReelComponent';
import WorksComponent from './pages/WorksComponent';
import AboutComponent from './pages/AboutComponent';
import ProjectComponent from './pages/ProjectComponent';
import HeaderComponent from './HeaderComponent';
//import CustomScrolling from './service/custom_smooth_scrolling'
import LocomotiveScroll from 'locomotive-scroll';

import { Content } from './App.style'
import { Nav } from './App.style'

import { StyleSheetManager } from 'styled-components'

import CSSPlugin from 'gsap/CSSPlugin';


const C = CSSPlugin;

// Stats
// const stats = new Stats(); stats.showPanel(0); document.body.appendChild(stats.dom);

const consoleStyle = ""





// Virtual Scroll Setup

const SetScroll = () => {

  const section = document.querySelector('.content')


  const scroll = new LocomotiveScroll({
    el: section,
    smooth: true,
    smoothMobile: true,
    touchMultiplier: 3.5,
    useKeyboard: true
  });

}




class App extends Component {


  componentDidMount() {

    SetScroll()
  }


  render() {
    return (

      <Router>

      <Content className="content">
        <div>
          <Route exact path='/' component={HomeComponent}></Route>
          <Route exact path='/reel' component={ReelComponent}></Route>
          <Route exact path='/works' component={WorksComponent}></Route>
          <Route exact path='/about' component={AboutComponent}></Route>
          <Route exact path='/project/:id' component={ProjectComponent}></Route>
        </div>
      </Content>


      </Router>
    )
  }
}

export default App;
