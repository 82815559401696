import React from 'react';
import Module01 from '../module_01/module_01';
import Module02 from '../module_02/module_02';
import Module03 from '../module_03/module_03';
import Module04 from '../module_04/module_04';
import Module05 from '../module_05/module_05';
import Module06 from '../module_06/module_06';
import Module07 from '../module_07/module_07';


const WorksLarge = () => (

  <div>
    <Module01/>
    <Module02/>
    <Module03/>
    <Module04/>
    <Module05/>
    <Module06/>
    <Module07/>
  </div>

)

export default WorksLarge;
