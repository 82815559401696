import React, { Component } from 'react'
import * as THREE from 'three'
import { Clock } from "three";
import GLTFLoader from 'three-gltf-loader'
import OrbitControls from 'three-orbitcontrols'
import { TweenMax, TimelineMax, Expo, Linear  } from 'gsap/all'




import ViewNavigation from './viewnavigation/ViewNavigation'
import Headline from './headline/Headline'
import Grain from './grain/Grain'

import flag from '../../images/bg.png'

const Items = []
const Positions = []
const S = 30

let CurrentView = 0
let CurrentSelection
let AnimatedCamera = true

var mouse = new THREE.Vector2(), INTERSECTED;

var iterator = 0


class ThreeScene extends Component {

   componentDidMount() {


      this.intervalId = setInterval(this.switchTo, 3000);

      const width = this.mount.clientWidth
      const height = this.mount.clientHeight

      this.mouse = new THREE.Vector2()

      // ADD SCENE
      this.scene = new THREE.Scene()

      // ADD CAMERA
      this.camera = new THREE.PerspectiveCamera(
        35,
        width / height,
        0.1,
        10000
      )

      // CAMERA START POSITION
      this.camera.position.z = 70
      //TweenMax.to(this.camera.position, 5, {z: 25, ease: Expo.easeOut, delay: 2.3})


      // ADD RENDERER
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      this.renderer.setClearColor('#FF6700', 0)
      this.renderer.setSize(width, height)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.mount.appendChild(this.renderer.domElement)

      this.clock = new Clock()

      this.textureText = new THREE.TextureLoader().load(flag);
      this.textureText.wrapS = THREE.RepeatWrapping;
      this.textureText.wrapT = THREE.RepeatWrapping;
      //this.textureText.minFilter=this.textureText.magFilter = THREE.NearestFilter
      this.textureText.repeat.set( 20, 20 );

      this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
      this.material2.flatShading = true;
      this.geometry = new THREE.TorusKnotBufferGeometry( 18, 8, 250, 50 );

      this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );
      this.scene.add( this.torusMesh );


      //this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.start()

      //this.switchTo()

      window.addEventListener(
      'resize',
      () => {
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(window.innerWidth, window.innerHeight)
      },
      false)
   }

   switchTo = () => {

     document.getElementsByClassName("numberEffect")[0].innerText = iterator;

     TweenMax.fromTo(
       document.querySelector('.progressCircle'),
       3,
       {
         y: '0vh',
         ease:Linear.easeInOut
       },
       {
         y: '100vh',
         ease:Linear.easeInOut
       }
     )



     this.scene.remove( this.torusMesh )

     iterator++
     if(iterator > 9) iterator = 0

     if(iterator == 0) {

       this.camera.position.z = 70
       this.textureText.repeat.set( 20, 20 );
       //textureText.repeat.y = §;

       this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
       this.geometry = new THREE.TorusKnotBufferGeometry( 18, 8, 250, 50 );

       this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );
       this.scene.add( this.torusMesh );
     }
     if(iterator == 1) {
       this.textureText.repeat.set( 33.5, 76.5 );
       this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
       this.material2.flatShading = true;
       this.geometry = new THREE.PlaneBufferGeometry( 420, 200, 1440 );

       this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );
       this.torusMesh.rotation.x = THREE.Math.degToRad(-20)
       this.torusMesh.rotation.z = THREE.Math.degToRad(20)
       this.torusMesh.position.x = 100
       this.torusMesh.position.z = 20

       this.scene.add( this.torusMesh );
      }
      if(iterator == 2) {

        this.textureText.repeat.set( 33.5, 90 );
        this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
        this.material2.flatShading = true;
        this.geometry = new THREE.PlaneBufferGeometry( 420, 200, 1440 );  //PlaneGeometry

        this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );

        this.scene.add( this.torusMesh );
       }
       if(iterator == 3) {

         this.textureText.rotation = THREE.Math.degToRad(90)
         this.textureText.repeat.set( 70, 90 );
         this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
         this.material2.flatShading = true;
         this.geometry = new THREE.CylinderGeometry( 15, 1, 300, 50, 50, true );
         this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );

         this.torusMesh.rotation.x = THREE.Math.degToRad(-98)
         this.torusMesh.rotation.z = THREE.Math.degToRad(0)

         this.torusMesh.position.x = 0
         this.torusMesh.position.z = 43

         this.torusMesh.scale.x = -1;
         this.camera.rotation.x = THREE.Math.degToRad(20)

         this.scene.add( this.torusMesh );
        }
        if(iterator == 4) {

          this.textureText.rotation = THREE.Math.degToRad(90)
          this.textureText.repeat.set( 90, 90 );
          this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
          this.material2.flatShading = true;
          this.geometry = new THREE.CylinderGeometry( 15, 1, 300, 50, 50, true );
          this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );

          this.torusMesh.rotation.x = THREE.Math.degToRad(0)
          this.torusMesh.rotation.y = THREE.Math.degToRad(9.3)
          this.torusMesh.rotation.z = THREE.Math.degToRad(90)

          this.torusMesh.position.x = 0
          this.torusMesh.position.y = 0
          this.torusMesh.position.z = 0
          this.torusMesh.position.z = 53.5
          this.torusMesh.scale.y = -1;
          this.torusMesh.scale.x = -1;

          //this.camera.position.x = THREE.Math.degToRad(-98)

          this.scene.add( this.torusMesh );
         }
         if(iterator == 5) {

           this.textureText.rotation = 0
           this.textureText.repeat.set( 33.5, 76.5 );
           this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
           this.material2.flatShading = true;
           this.geometry = new THREE.PlaneBufferGeometry( 420, 200, 1440 );

           this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );
           this.torusMesh.rotation.x = THREE.Math.degToRad(-68)
           this.torusMesh.rotation.y = 0
           this.torusMesh.rotation.z = THREE.Math.degToRad(0)
           this.torusMesh.position.x = 100
           this.torusMesh.position.z = 44

           this.scene.add( this.torusMesh );
          }
          if(iterator == 6) {

            this.textureText.repeat.set( 33.5, 90 );
            this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
            this.material2.flatShading = true;
            this.geometry = new THREE.PlaneBufferGeometry( 420, 200, 1440 );  //PlaneGeometry

            this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );

            this.scene.add( this.torusMesh );
          }
          if(iterator == 7) {

            this.textureText.repeat.set( 9, 13 );
            this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
            this.material2.flatShading = true;
            this.material2.morphTargets = true;
            this.geometry = this.createTwistGeometry()

            this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );

            this.torusMesh.morphTargetInfluences[ 1 ] = .5

            this.scene.add( this.torusMesh );
          }
          if(iterator == 8) {

            this.textureText.repeat.set( 9, 21 );
            this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
            this.material2.flatShading = true;
            this.material2.morphTargets = true;
            this.geometry = this.createTwistGeometry()

            this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );

            this.obj = {siteY:1};
            TweenMax.to(this.obj,2.8, {siteY:-.8, ease:Expo.easeInOut, onUpdate:this.drawCanvas });

            this.scene.add( this.torusMesh );
          }
          if(iterator == 9) {

            this.textureText.repeat.set( 9, 23 );
            this.material2 = new THREE.MeshBasicMaterial({ map: this.textureText, side: THREE.DoubleSide});
            this.material2.flatShading = true;
            this.material2.morphTargets = true;
            this.geometry = this.createTwistGeometry()

            this.torusMesh = new THREE.Mesh( this.geometry, this.material2 );

            this.obj = {siteY:0};
            TweenMax.to(this.obj,2.5, {siteY:.5, ease:Expo.easeInOut, onUpdate:this.drawCanvas2 });

            this.scene.add( this.torusMesh );
          }

   }

   drawCanvas = () => {
      if(this.torusMesh.morphTargetInfluences && this.obj) this.torusMesh.morphTargetInfluences[ 0 ] = this.obj.siteY
   }

   drawCanvas2 = () => {
      if(this.torusMesh.morphTargetInfluences && this.obj) this.torusMesh.morphTargetInfluences[ 1 ] = this.obj.siteY
   }

   componentWillUnmount() {
      window.removeEventListener('resize', null)
      this.stop()
      this.renderPass = null;
      this.effectPass = null;
      this.composer = null;
      this.camera = null;
      this.textureText = null;
      this.torusMesh = null;
      this.scene = null;
      this.mount.removeChild(this.renderer.domElement)
      this.renderer = null;
   }

   twister = ( waveSize, magnitude ) => {
     const theTime =  performance.now() * .008;
     var pos = this.torusMesh.geometry.attributes.position;
     let center = new THREE.Vector3(0,0,0);
     var vec3 = new THREE.Vector3(); // re-use

     for ( var i = 0, l = pos.count; i < l; i ++ ) {
       vec3.fromBufferAttribute(pos, i);
       vec3.sub(center);
       var z = Math.sin( vec3.length() /- waveSize + (theTime)) * magnitude;
       pos.setZ(i, z);
     }
     pos.needsUpdate = true
   }

   twister2 = ( waveSize, magnitude ) => {
     const theTime =  performance.now() * .0025;
     var pos = this.torusMesh.geometry.attributes.position;
     let center = new THREE.Vector3(0,0,0);
     var vec3 = new THREE.Vector3(); // re-use

     for ( var i = 0, l = pos.count; i < l; i ++ ) {
       vec3.fromBufferAttribute(pos, i);
       vec3.sub(center);
       var z = Math.sin( vec3.length() /- waveSize + (theTime)) * magnitude;
       pos.setZ(i, z);
     }
     pos.needsUpdate = true
   }

   wavesBuffer = ( waveSize, magnitude ) => {
      const theTime =  performance.now() * .001;
      var pos = this.torusMesh.geometry.attributes.position;
      let center = new THREE.Vector3(0,0,0);
      var vec3 = new THREE.Vector3(); // re-use

      for ( var i = 0, l = pos.count; i < l; i ++ ) {
        vec3.fromBufferAttribute(pos, i);
    		vec3.sub(center);
        var z = Math.sin( vec3.length() /- waveSize + (theTime)) * magnitude;
        pos.setZ(i, z);
      }
      pos.needsUpdate = true
   }

   createTwistGeometry = () => {

				var geometry = new THREE.BoxBufferGeometry( 4, 2, 2, 32, 32, 32 );

				// create an empty array to  hold targets for the attribute we want to morph
				// morphing positions and normals is supported
				geometry.morphAttributes.position = [];

				// the original positions of the cube's vertices
				var positions = geometry.attributes.position.array;

				// for the first morph target we'll move the cube's vertices onto the surface of a sphere
				var spherePositions = [];

				// for the second morph target, we'll twist the cubes vertices
				var twistPositions = [];
				var direction = new THREE.Vector3( 1, 0, 0 ).normalize();
				var vertex = new THREE.Vector3();

				for ( var i = 0; i < positions.length; i += 3 ) {

					var x = positions[ i ];
					var y = positions[ i + 1 ];
					var z = positions[ i + 2 ];

					spherePositions.push(

						x * Math.sqrt( 1 - ( y * y / 2 ) - ( z * z / 2 ) + ( y * y * z * z / 3 ) ),
						y * Math.sqrt( 1 - ( z * z / 2 ) - ( x * x / 2 ) + ( z * z * x * x / 3 ) ),
						z * Math.sqrt( 1 - ( x * x / 2 ) - ( y * y / 2 ) + ( x * x * y * y / 3 ) )

					);

					// stretch along the x-axis so we can see the twist better
					vertex.set( x * 2, y, z );

					vertex.applyAxisAngle( direction, Math.PI * x / 2 ).toArray( twistPositions, twistPositions.length );

				}


				// add the spherical positions as the first morph target
				geometry.morphAttributes.position[ 0 ] = new THREE.Float32BufferAttribute( spherePositions, 3 );

				// add the twisted positions as the second morph target
				geometry.morphAttributes.position[ 1 ] = new THREE.Float32BufferAttribute( twistPositions, 3 );

				return geometry;

	 }

   start = () => {
      if (!this.frameId) {
        this.frameId = requestAnimationFrame(this.animate)
      }
   }

   stop = () => {
     cancelAnimationFrame(this.frameId)
   }

   animate = () => {


     if(iterator == 0) {

       var rotSpeed = .002

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = x * Math.cos(rotSpeed) + z * Math.sin(rotSpeed)
           this.camera.position.y = x * Math.cos(rotSpeed) + z * Math.sin(rotSpeed)
           this.camera.position.z = z * Math.cos(rotSpeed) - x * Math.sin(rotSpeed)

           this.textureText.offset.y += 0.03

           this.camera.lookAt(new THREE.Vector3(0,0,0))
     }
     if(iterator == 1) {

       var rotSpeed = .002

       this.wavesBuffer( 2.8 , 6);

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 70

           this.textureText.offset.y += 0.03

           this.camera.lookAt(new THREE.Vector3(0,0,0))
     }
     if(iterator == 2) {

       var rotSpeed = .002

       this.twister( 10 , 76 )

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 70

           this.textureText.offset.y += 0.03

           this.camera.lookAt(new THREE.Vector3(0,0,0))
     }
     if(iterator == 3) {

       var rotSpeed = .002

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 80

           this.textureText.offset.x += 0.07
     }
     if(iterator == 4) {

       var rotSpeed = .002

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 80

           this.textureText.offset.y += 0.14

           this.camera.lookAt(new THREE.Vector3(0,0,0))
     }
     if(iterator == 5) {

       var rotSpeed = .002

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 70

           this.textureText.offset.y -= 0.2

           this.camera.lookAt(new THREE.Vector3(0,0,0))
     }
     if(iterator == 6) {

       var rotSpeed = .002

       this.twister2( 16 , 46 )

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 70

           this.textureText.offset.y += 0.03

           this.camera.lookAt(new THREE.Vector3(0,0,0))
     }
     if(iterator == 7) {

       var rotSpeed = .002

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 4

           this.torusMesh.rotation.x += 0.03

           this.camera.lookAt(new THREE.Vector3(0,0,0))
     }
     if(iterator == 8) {

       var rotSpeed = .002

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 3.6

           //this.textureText.offset.y += 0.03
           this.torusMesh.rotation.x += 0.017

           this.camera.lookAt(new THREE.Vector3(0,0,0))
     }
     if(iterator == 9) {

       var rotSpeed = .002

       var x = this.camera.position.x,
           y = this.camera.position.y,
           z = this.camera.position.z;

           this.camera.position.x = 0
           this.camera.position.y = 0
           this.camera.position.z = 4

           this.textureText.offset.x -= 0.04
           //this.torusMesh.rotation.x += 0.03

           this.camera.lookAt(new THREE.Vector3(0,0,0))
     }

     this.renderScene()
     this.frameId = window.requestAnimationFrame(this.animate)
   }

   renderScene = () => {
     this.renderer.render(this.scene, this.camera)
   }

   render(){
     return(
       <div style={{
                    width: '100vw',
                    height: '100vh',
                  }}>
         <div class="progressCircle" style={{ height: '50px', width: '12px', backgroundColor: '#000', borderRadius: '200px', display: 'inline-block', position: 'absolute', right: '20px', marginTop: '-80px'}}>
         <div class="numberEffect" style={{ fontSize: '32px', marginLeft: '10px', marginTop: '50px', position: 'relative' }}>2</div>
         </div>
         <div
           style={{
             width: '100vw',
             height: '100vh',
             display: 'flex',
             backgroundColor: '#FF6700'
           }}
           ref={(mount) => { this.mount = mount }}
         >

         </div>
       </div>
     )
   }
}
export default ThreeScene
