import styled from 'styled-components'


// Background
export const BackgroundLarge = styled.div`
  background-color: #FF5500;
`
export const BackgroundMedium = styled.div`
  background-color: #1E1D1D;
`
export const BackgroundSmall = styled.div`
  background-color: #6600FF;
`

// Text
export const TextLarge = styled.p`
  font-size: 16px;
  color: #000;
  user-select: none;
`
export const TextLargeClean = styled.p`
  font-size: 16px;
  color: #000;
  margin-top: 0px;
  user-select: none;
`
export const TextMedium = styled.p`
  font-size: 13px;
  color: #FF6700;
  user-select: none;
`
export const TextMediumClean = styled.p`
  font-size: 13px;
  color: #FF6700;
  margin-top: 0px;
  user-select: none;
`
export const TextSmall = styled.p`
  font-size: 12px;
  color: #FF6700;
  user-select: none;
`

// Title
export const TitleLarge = styled.p`
  margin-top: 0px;
  font-size: 60px;
  letter-spacing: -3px;
  color: #000;
  line-height: 95%;
  user-select: none;
  transform: translate(0,105%);
`
export const TitleMedium = styled.p`
  margin-top: 0px;
  font-size: 80px;
  letter-spacing: -1.6px;
  color: #EDEDED;
  user-select: none;
`
export const TitleSmall = styled.p`
  margin-top: 0px;
  font-size: 40px;
  letter-spacing: -2px;
  color: #000;
  line-height: 95%;
  user-select: none;
  transform: translate(0,105%);
`

// Logo
export const LetterOne = styled.div`
  background: url(${require('../../../svg/1.svg')}) no-repeat;
  background-size: 100% 100%;
  background-position-y: top;
  width: 100%;
  height: 100%;
`
export const LetterTwo = styled.div`
  background: url(${require('../../../svg/2.svg')}) no-repeat;
  background-size: 100% 100%;
  background-position-y: top;
  width: 100%;
  height: 100%;
`
export const LetterThree = styled.div`
  background: url(${require('../../../svg/3.svg')}) no-repeat;
  background-size: 100% 100%;
  background-position-y: top;
  width: 100%;
  height: 100%;
`
export const LogoSmall = styled.div`
  background: url(${require('../../../svg/logo.svg')}) no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
`
export const LogoTiny = styled.div`
  background: url(${require('../../../svg/logo.svg')}) no-repeat;
  width: 50px;
  height: 20px;
  background-size: 45px auto;
`



const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`

const Wrapper2 = styled.section`
  padding: 4em;
  background: papayawhip;
`

const Thing = styled.button`
  color: blue;

  ::before {
    content: '🚀';
  }

  :hover {
    color: red;
  }
`

export const Wrapper = styled.div`
  overflow: hidden;
  height: 68px;
`

export const HeadlineWrapper = styled.div`
  position: absolute;
  margin-top: 2vh;
  left: 1vh;
`
