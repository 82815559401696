import React from 'react';
import MediaQuery from 'react-responsive';
import AboutXLarge from './About/AboutXLarge'
import AboutLarge from './About/AboutLarge'
import AboutMedium from './About/AboutMedium'
import AboutSmall from './About/AboutSmall'


const Breakpoints = { large: 1200, medium: 900, small: 500 }

const About = () => (

    <div>
        {/* X LARGE */}

        <MediaQuery minWidth={1500}>
            <AboutXLarge/>
        </MediaQuery>

        {/* LARGE */}

        <MediaQuery minWidth={1200} maxWidth={1499}>
            <AboutLarge/>
        </MediaQuery>

        {/* MEDIUM */}

        <MediaQuery minWidth={801} maxWidth={1199}>
            <AboutMedium/>
        </MediaQuery>

        {/* SMALL */}

        <MediaQuery minWidth={1} maxWidth={800}>
            <AboutSmall/>
        </MediaQuery>

    </div>
)

export default About;
