import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
//import { Waypoint } from 'react-waypoint';
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'
import { useInView } from 'react-hook-inview'
import { BackgroundLarge } from './module_01.style';
import { HeadlineWrapper } from './module_01.style';
import { HeadlineOne } from './module_01.style';
import { HeadlineTwo } from './module_01.style';
import { HeadlineThree } from './module_01.style';
import { Wrapper } from './module_01.style';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {

  TweenMax.to(
    document.querySelector( String("."+HeadlineOne.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.2
  })
  TweenMax.to(
    document.querySelector( String("."+HeadlineTwo.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.4
  })
  TweenMax.to(
    document.querySelector( String("."+HeadlineThree.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.6
  })

}

const Module01 = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (

    <div>

      {/* LARGE */}

      <MediaQuery minWidth={1200}>
        <BackgroundLarge>
          <Container fluid>

            <Row align="start" justify="start" style={{ height: '100vh' }}>
              <HeadlineWrapper>

                <div ref={ref}>
                {isVisible
                  ? handleWaypointEnter()
                  : ''
                }
                </div>

                <Wrapper><HeadlineOne>RE</HeadlineOne></Wrapper>
                <Wrapper><HeadlineTwo>AS</HeadlineTwo></Wrapper>
                <Wrapper><HeadlineThree>ON</HeadlineThree></Wrapper>
              </HeadlineWrapper>
            </Row>
            <Row align="start" justify="start" style={{ height: '2vw' }}>
                      <div></div>
            </Row>

          </Container>
        </BackgroundLarge>
      </MediaQuery>

      {/* MEDIUM */}

      <MediaQuery minWidth={801} maxWidth={1199}>
        <BackgroundLarge>
          <Container fluid>

            <Row align="start" justify="start" style={{ height: '100vh' }}>

              <HeadlineWrapper>

                <div ref={ref}>
                {isVisible
                  ? handleWaypointEnter()
                  : ''
                }
                </div>

                <Wrapper><HeadlineOne>RE</HeadlineOne></Wrapper>
                <Wrapper><HeadlineTwo>AS</HeadlineTwo></Wrapper>
                <Wrapper><HeadlineThree>ON</HeadlineThree></Wrapper>
              </HeadlineWrapper>
            </Row>
            <Row align="start" justify="start" style={{ height: '2vw' }}>
                      <div></div>
            </Row>

          </Container>
        </BackgroundLarge>
      </MediaQuery>

      {/* SMALL */}

      <MediaQuery minWidth={1} maxWidth={800}>
        <BackgroundLarge>
          <Container fluid>

            <Row align="start" justify="start" style={{ height: '130vw' }}>

              <HeadlineWrapper>

                <div ref={ref}>
                {isVisible
                  ? handleWaypointEnter()
                  : ''
                }
                </div>

                <Wrapper><HeadlineOne style={{ fontSize: '50vw' }}>RE</HeadlineOne></Wrapper>
                <Wrapper><HeadlineTwo style={{ fontSize: '50vw', marginLeft: '39.6vw'  }}>AS</HeadlineTwo></Wrapper>
                <Wrapper><HeadlineThree style={{ fontSize: '50vw' }}>ON</HeadlineThree></Wrapper>
              </HeadlineWrapper>
            </Row>
            <Row align="start" justify="start" style={{ height: '2vw' }}>
                <div></div>
            </Row>

          </Container>
        </BackgroundLarge>
      </MediaQuery>

    </div>
  )
}

export default Module01;
