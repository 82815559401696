import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { useInView } from 'react-hook-inview'
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'

import { BackgroundLarge } from './module_01.style';
import { HeadlineWrapper } from './module_01.style';
import { TextLarge } from './module_01.style';
import { HeadlineOne } from './module_01.style';
import { HeadlineTwo } from './module_01.style';
import { HeadlineThree } from './module_01.style';
import { Wrapper } from './module_01.style';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {


  TweenMax.to(
    document.querySelector( String("."+HeadlineOne.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.2
  })
  TweenMax.to(
    document.querySelector( String("."+HeadlineTwo.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.4
  })

}

const Module01   = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (

    <div>

      {/* LARGE */}

      <MediaQuery minWidth={1200}>
        <BackgroundLarge>
          <Container fluid>

              <Row align="start" justify="start" style={{ paddingTop: '15px', marginBottom: '30vh' }}>
                <Col sm={2}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition.</TextLarge></Col>
                <Col sm={2}></Col>
                <Col sm={2}><TextLarge>Because we strongly believe that magic happens intuition.</TextLarge></Col>
                <Col sm={2}><TextLarge>Because we strongly believe that magic happens intuition.</TextLarge></Col>
                <Col sm={2}><TextLarge>Because we strongly believe that magic happens intuition.</TextLarge></Col>
              </Row>

              <div ref={ref}>
                {isVisible
                  ? handleWaypointEnter()
                  : ''
                }
              </div>

              <Row align="start" justify="start" style={{ marginBottom: '30vh' }}>
                <Col sm={4}></Col>
                <Col sm={6}>
                  <HeadlineWrapper>
                    <Wrapper><HeadlineOne>REASON</HeadlineOne></Wrapper>
                    <Wrapper><HeadlineTwo>for</HeadlineTwo></Wrapper>
                  </HeadlineWrapper>
                </Col>
              </Row>

              <Row align="start" justify="start" style={{ height: '10vh' }}>
                <div></div>
              </Row>

            </Container>
          </BackgroundLarge>
       </MediaQuery>

       {/* MEDIUM */}

       <MediaQuery minWidth={801} maxWidth={1199}>
         <BackgroundLarge>
           <Container fluid>

               <Row align="start" justify="start" style={{ paddingTop: '15px', marginBottom: '30vh' }}>
                 <Col sm={3}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition.</TextLarge></Col>
                 <Col sm={3}></Col>
                 <Col sm={3}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition.</TextLarge></Col>
                 <Col sm={3}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition.</TextLarge></Col>
               </Row>

               <div ref={ref}>
                 {isVisible
                   ? handleWaypointEnter()
                   : ''
                 }
               </div>

               <Row align="start" justify="start" style={{ marginBottom: '30vh' }}>
                 <Col sm={3}></Col>
                 <Col sm={9}>
                   <HeadlineWrapper>
                     <Wrapper><HeadlineOne>REASON</HeadlineOne></Wrapper>
                     <Wrapper><HeadlineTwo>for</HeadlineTwo></Wrapper>
                   </HeadlineWrapper>
                 </Col>
               </Row>

               <Row align="start" justify="start" style={{ height: '10vh' }}>
                 <div></div>
               </Row>

             </Container>
           </BackgroundLarge>
       </MediaQuery>

       {/* SMALL */}

       <MediaQuery minWidth={1} maxWidth={800}>
         <BackgroundLarge>
           <Container fluid>

               <Row align="start" justify="start" style={{ paddingTop: '15px', marginBottom: '2vh' }}>
                 <Col sm={6}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition.</TextLarge></Col>
                 <Col sm={6}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition.</TextLarge></Col>
               </Row>

               <Row align="start" justify="start" style={{ marginBottom: '20vh' }}>
                 <Col sm={6}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition.</TextLarge></Col>
               </Row>

               <div ref={ref}>
                 {isVisible
                   ? handleWaypointEnter()
                   : ''
                 }
               </div>

               <Row align="start" justify="start" style={{ marginBottom: '25vh' }}>
                 <Col sm={12}>
                   <HeadlineWrapper>
                     <Wrapper><HeadlineOne style={{ fontSize: '14vh' }}>REASON</HeadlineOne></Wrapper>
                     <Wrapper><HeadlineTwo  style={{ fontSize: '14vh' }}>for</HeadlineTwo></Wrapper>
                   </HeadlineWrapper>
                 </Col>
               </Row>

               <Row align="start" justify="start" style={{ height: '10vh' }}>
                 <div></div>
               </Row>

             </Container>
           </BackgroundLarge>
       </MediaQuery>

    </div>
  )
}

export default Module01;
