import React, { Component } from 'react'
import Reel from '../layouts/reel/Reel'


class ReelComponent extends Component {

  componentDidMount() {

    document.querySelector('.content').style.overflowY = "hidden"
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    return (
      <Reel/>
    )
  }
}

export default ReelComponent;
