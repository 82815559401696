import React from 'react';
import MediaQuery from 'react-responsive';
import ProjectGridLarge from './ProjectGrid/ProjectGridLarge'
import ProjectGridMedium from './ProjectGrid/ProjectGridMedium'
import ProjectGridSmall from './ProjectGrid/ProjectGridSmall'


const Breakpoints = { large: 1200, medium: 900, small: 500 }

const ProjectGrid = () => (

    <div>

        {/* LARGE */}

        <MediaQuery minWidth={801}>
          <ProjectGridLarge/>
        </MediaQuery>

        {/* SMALL */}

        <MediaQuery minWidth={1} maxWidth={800}>
          <ProjectGridSmall/>
        </MediaQuery>

    </div>
)

export default ProjectGrid;
