import styled from 'styled-components'

export const BackgroundLarge = styled.div`
  background-color: #1F2023;
`

export const HeadlineOne = styled.div`
  font-family: 'reason01';
  font-size: 16vh;
  color: #F6F6F6;
  line-height: 75%;
  user-select: none;
  transform: translate(0,102%);
`

export const HeadlineTwo = styled.div`
  font-family: 'reason01';
  font-size: 16vh;
  color: #F6F6F6;
  line-height: 75%;
  user-select: none;
  transform: translate(0,102%);
`

export const HeadlineThree = styled.div`
  font-family: 'reason01';
  font-size: 43vh;
  color: #F6F6F6;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const Wrapper = styled.div`
  overflow: hidden;
`

export const HeadlineWrapper = styled.div`
  position: absolute;
  margin-top: 2vh;
  left: 1vh;
`
// Text
export const TextLarge = styled.p`
  font-size: 14px;
  color: #F6F6F6;
  margin-top: 0px;
  user-select: none;
`
