import React, {useEffect, useRef} from 'react'
import { HeadlineWrapper } from './Headline.style'
import { HeadlineOne } from './Headline.style'
import { HeadlineTwo } from './Headline.style'
import { HeadlineThree } from './Headline.style'
import { Wrapper } from './Headline.style'
import { TweenMax, TimelineMax, Expo } from 'gsap/all'
import CSSPlugin from 'gsap/CSSPlugin';

import { StyleSheetManager } from 'styled-components'

const C = CSSPlugin;

const Headline = () => {

  let logoElement = useRef(HeadlineOne)

  console.log("."+HeadlineOne.styledComponentId)
  console.log(document.querySelector("."+HeadlineOne.styledComponentId))
  console.log("."+HeadlineTwo.styledComponentId)
  console.log("."+HeadlineThree.styledComponentId)

  useEffect(() => {
    TweenMax.to(
      document.querySelector( String("."+HeadlineOne.styledComponentId) ),
      1.4,
    {
      y:"0px",
      ease:Expo.easeOut,
      delay:.9
    })

    TweenMax.to(
      document.querySelector( String("."+HeadlineTwo.styledComponentId) ),
      1.4,
    {
      y:"0px",
      ease:Expo.easeOut,
      delay:.6
    })

    TweenMax.to(
      document.querySelector( String("."+HeadlineThree.styledComponentId) ),
      1.4,
    {
      y:"0px",
      ease:Expo.easeOut,
      delay:.3
    })

    console.log('>> Headline ::', document.querySelector( String("."+HeadlineOne.styledComponentId) ));
  }, [])

  return (
      <HeadlineWrapper>
        <Wrapper><HeadlineOne>RE</HeadlineOne></Wrapper>
        <Wrapper><HeadlineTwo>AS</HeadlineTwo></Wrapper>
        <Wrapper><HeadlineThree>ON</HeadlineThree></Wrapper>
      </HeadlineWrapper>
)}

export default Headline;
