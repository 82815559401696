import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class Reel extends Component {
  render () {
    return (
      <div>

      </div>
    )
  }
}

export default Reel;
