import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { BackgroundLarge } from './ProjectGrid.style';
import { TextLarge } from './ProjectGrid.style';
import { TextLargeClean } from './ProjectGrid.style';
import { TitleLarge } from './ProjectGrid.style';
import { LetterOne } from './ProjectGrid.style';
import { LetterTwo } from './ProjectGrid.style';
import { LetterThree } from './ProjectGrid.style';
import { LogoTiny } from './ProjectGrid.style';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const AboutMedium = () => (

    <BackgroundLarge>
      <Container fluid>
        <Row align="start" justify="start" style={{ height: '16.66vw' }}>
          <Col sm={2}><TextLarge>NodeSystem</TextLarge></Col>
          <Col sm={2}><TextLarge>NodeSystem</TextLarge></Col>
          <Col sm={2}><TextLarge>NodeSystem</TextLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '16.66vw' }}>
          <Col sm={2}><TextLarge>NodeSystem</TextLarge></Col>
          <Col sm={2}></Col>
          <Col sm={8}><TitleLarge>NodeSystem</TitleLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '16.66vw' }}>
          <Col sm={2}><TextLarge>NodeSystem</TextLarge></Col>
          <Col sm={2}><TextLarge>Digital Product<br></br>Consultancy</TextLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '16.66vw', paddingBottom: '6px' }}>
          <Col sm={2} style={{ height: '16.66vw', paddingRight: '0px', paddingLeft: '10px' }}><LetterOne></LetterOne></Col>
          <Col sm={2} style={{ height: '16.66vw', paddingRight: '0px', paddingLeft: '10px'  }}><LetterTwo></LetterTwo></Col>
          <Col sm={2} style={{ height: '16.66vw', paddingRight: '0px', paddingLeft: '10px'  }}><LetterThree></LetterThree></Col>
          <Col sm={2}></Col>
          <Col sm={2}><TextLargeClean>DBR is a modular display type system available in 3 cuts</TextLargeClean></Col>
          <Col sm={2}><LogoTiny></LogoTiny></Col>
        </Row>
      </Container>
    </BackgroundLarge>

)

export default AboutMedium;
