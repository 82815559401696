import styled from 'styled-components'


export const GrainOverlay = styled.div`
  position: absolute;
  background: url(${require('../../../images/grain.png')}) no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
`
