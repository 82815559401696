import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { useInView } from 'react-hook-inview'
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'

import { Wrapper } from './module_06.style';
import { Module06Wrapper } from './module_06.style'
import { Module06HeadlineOne } from './module_06.style'
import { Module06HeadlineOne2 } from './module_06.style'
import { Module06HeadlineTwo } from './module_06.style'
import { Module06HeadlineThree } from './module_06.style'
import { Module06HeadlineWrapper } from './module_06.style'
import { Module06Photo } from './module_06.style'
import { Module06TextWrapper } from './module_06.style'

import { TitleLarge2 } from './module_06.style';
import { TitleLarge3 } from './module_06.style';
import { TitleLarge4 } from './module_06.style';
import { TitleLarge5 } from './module_06.style';
import { BackgroundLarge } from './module_06.style';

import { TitleLarge2s } from './module_06.style';
import { TitleLarge3s } from './module_06.style'
import { TitleLarge4s } from './module_06.style'
import { TitleLarge5s } from './module_06.style'
import { TitleLarge6s } from './module_06.style'

import { TitleLarge2B } from './module_06.style';
import { TitleLarge3B } from './module_06.style'
import { TitleLarge4B } from './module_06.style'
import { TitleLarge5B } from './module_06.style'


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {


  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.2
  })
  TweenMax.to(
    document.querySelector( String("."+Module06HeadlineOne2.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.4
  })
}

const Module06   = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (


  <div>

    {/* LARGE */}

    <MediaQuery minWidth={1200}>
      <BackgroundLarge>
        <Container fluid>

        <div ref={ref}>
        {isVisible
          ? handleWaypointEnter()
          : ''
        }
        </div>

          <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
            <Col sm={12}>
              <Module06Wrapper>
                <Module06Photo></Module06Photo>
                <Module06TextWrapper>
                  <Wrapper><Module06HeadlineOne>BETWEEN</Module06HeadlineOne></Wrapper>
                  <Wrapper><Module06HeadlineOne2>THE HOURS</Module06HeadlineOne2></Wrapper>
                  <Module06HeadlineWrapper>
                    <TitleLarge2B>The</TitleLarge2B>
                    <TitleLarge5B>CALM SHADOWS</TitleLarge5B>
                    <TitleLarge3B>In Praise</TitleLarge3B>
                      <br></br>
                    <TitleLarge4B>of  </TitleLarge4B>
                    <TitleLarge3B>Shadows</TitleLarge3B>
                  </Module06HeadlineWrapper>
              </Module06TextWrapper>
              </Module06Wrapper>
            </Col>
          </Row>
        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* MEDIUM */}

    <MediaQuery minWidth={801} maxWidth={1199}>
      <BackgroundLarge>
        <Container fluid>

        <div ref={ref}>
        {isVisible
          ? handleWaypointEnter()
          : ''
        }
        </div>

          <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
            <Col sm={12}>
              <Module06Wrapper>
                <Module06Photo></Module06Photo>
                <Module06TextWrapper>
                  <Wrapper><Module06HeadlineOne>BETWEEN</Module06HeadlineOne></Wrapper>
                  <Wrapper><Module06HeadlineOne2>THE HOURS</Module06HeadlineOne2></Wrapper>
                  <Module06HeadlineWrapper>
                    <TitleLarge2>The</TitleLarge2>
                    <TitleLarge5>CALM SHADOWS</TitleLarge5>
                    <TitleLarge3>In Praise</TitleLarge3>
                      <br></br>
                    <TitleLarge4>of</TitleLarge4>
                    <TitleLarge3>Shadows</TitleLarge3>
                  </Module06HeadlineWrapper>
              </Module06TextWrapper>
              </Module06Wrapper>
            </Col>
          </Row>
        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* SMALL */}

    <MediaQuery minWidth={1} maxWidth={800}>
      <BackgroundLarge>
        <Container fluid>

        <div ref={ref}>
        {isVisible
          ? handleWaypointEnter()
          : ''
        }
        </div>

          <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
            <Col sm={12}>
              <Module06Wrapper>
                <Module06Photo></Module06Photo>
                <Module06TextWrapper>
                  <Wrapper><Module06HeadlineOne>BETWEEN</Module06HeadlineOne></Wrapper>
                  <Wrapper><Module06HeadlineOne2>THE HOURS</Module06HeadlineOne2></Wrapper>
                  <Module06HeadlineWrapper>
                    <TitleLarge2s>The</TitleLarge2s>
                    <TitleLarge5s>CALM SHADOWS</TitleLarge5s>
                    <TitleLarge3s>In Praise</TitleLarge3s>
                      <br></br>
                    <TitleLarge4s>of  </TitleLarge4s>
                    <TitleLarge3s>Shadows</TitleLarge3s>
                  </Module06HeadlineWrapper>
              </Module06TextWrapper>
              </Module06Wrapper>
            </Col>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

  </div>
)
}

export default Module06;
