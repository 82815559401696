import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'
import { useInView } from 'react-hook-inview'

import { Module03Wrapper } from './module_03.style'
import { Wrapper } from './module_03.style'
import { Module03HeadlineOne1 } from './module_03.style'
import { Module03HeadlineOne2 } from './module_03.style'
import { Module03HeadlineOne3 } from './module_03.style'
import { Module03HeadlineOne4 } from './module_03.style'
import { Module03HeadlineTwo } from './module_03.style'
import { Module03HeadlineThree } from './module_03.style'
import { Module03HeadlineWrapper } from './module_03.style'
import { Module03Photo } from './module_03.style'
import { Module03TextWrapper } from './module_03.style'

import { TitleLarge2 } from './module_03.style';
import { TitleLarge3 } from './module_03.style';
import { TitleLarge4 } from './module_03.style';
import { TitleLarge5 } from './module_03.style';
import { BackgroundLarge } from './module_03.style';

import { Module03Photos } from './module_03.style'
import { TitleLarge2s } from './module_03.style';
import { TitleLarge3s } from './module_03.style'
import { TitleLarge4s } from './module_03.style'
import { TitleLarge5s } from './module_03.style'
import { TitleLarge6s } from './module_03.style'

import { TitleLarge2B } from './module_03.style';
import { TitleLarge3B } from './module_03.style'
import { TitleLarge4B } from './module_03.style'
import { TitleLarge5B } from './module_03.style'


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {

console.log('03');

  TweenMax.to(
    document.querySelector( String("."+Module03HeadlineOne1.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.4
  })
  TweenMax.to(
    document.querySelector( String("."+Module03HeadlineOne2.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.6
  })
  TweenMax.to(
    document.querySelector( String("."+Module03HeadlineOne3.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.8
  })
  TweenMax.to(
    document.querySelector( String("."+Module03HeadlineOne4.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:1
  })
  TweenMax.to(
    document.querySelector( String("."+Module03Photo.styledComponentId) ),
    3,
  {
    padding:"5vw",
    ease:Expo.easeOut,
    delay:0
  })
  TweenMax.to(
    document.querySelector( String("."+Module03Photo.styledComponentId) ),
    3,
  {
    x:"-11.4vw",
    ease:Expo.easeOut,
    delay:0
  })
  TweenMax.to(
    document.querySelector( String("."+Module03Photo.styledComponentId) ),
    3,
  {
    y:"3vw",
    ease:Expo.easeOut,
    delay:0
  })
}

const Module03   = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (

    <div>

      {/* LARGE */}

      <MediaQuery minWidth={1200}>
        <BackgroundLarge>
          <Container fluid>

            <Row align="start" justify="start" style={{ height: '20vw' }}>
            </Row>

            <div ref={ref}>
            {isVisible
              ? handleWaypointEnter()
              : ''
            }
            </div>

            <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
              <Col sm={12}>
                <Module03Wrapper>
                  <Module03Photo></Module03Photo>
                  <Module03TextWrapper>
                    <Wrapper>
                      <Module03HeadlineOne1>
                        BETWEEN
                      </Module03HeadlineOne1>
                    </Wrapper>
                    <Wrapper>
                      <Module03HeadlineOne2>
                        THE HOURS
                      </Module03HeadlineOne2>
                    </Wrapper>
                  <Module03HeadlineWrapper>
                  <TitleLarge2B>The</TitleLarge2B>
                  <TitleLarge5B>CALM SHADOWS</TitleLarge5B>
                  <TitleLarge3B>In Praise</TitleLarge3B>
                    <br></br>
                  <TitleLarge4B>of  </TitleLarge4B>
                  <TitleLarge3B>Shadows</TitleLarge3B>
                  </Module03HeadlineWrapper>
                    <Wrapper>
                      <Module03HeadlineOne3>
                        STEPILE
                      </Module03HeadlineOne3>
                    </Wrapper>
                    <Wrapper>
                      <Module03HeadlineOne4>
                        CHARTES
                      </Module03HeadlineOne4>
                    </Wrapper>
                </Module03TextWrapper>
                </Module03Wrapper>
              </Col>
            </Row>

          </Container>
        </BackgroundLarge>
      </MediaQuery>

      {/* MEDIUM */}

      <MediaQuery minWidth={801} maxWidth={1199}>
        <BackgroundLarge>
          <Container fluid>

            <Row align="start" justify="start" style={{ height: '14vw' }}>
            </Row>

            <div ref={ref}>
            {isVisible
              ? handleWaypointEnter()
              : ''
            }
            </div>

            <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
              <Col sm={12}>
                <Module03Wrapper>
                  <Module03Photo></Module03Photo>
                  <Module03TextWrapper>
                    <Wrapper>
                      <Module03HeadlineOne1>
                        BETWEEN
                      </Module03HeadlineOne1>
                    </Wrapper>
                    <Wrapper>
                      <Module03HeadlineOne2>
                        THE HOURS
                      </Module03HeadlineOne2>
                    </Wrapper>
                  <Module03HeadlineWrapper>
                    <TitleLarge2>The</TitleLarge2>
                    <TitleLarge5>CALM SHADOWS</TitleLarge5>
                    <TitleLarge3>In Praise</TitleLarge3>
                      <br></br>
                    <TitleLarge4>of</TitleLarge4>
                    <TitleLarge3>Shadows</TitleLarge3>
                  </Module03HeadlineWrapper>
                    <Wrapper>
                      <Module03HeadlineOne3>
                        STEPILE
                      </Module03HeadlineOne3>
                    </Wrapper>
                    <Wrapper>
                      <Module03HeadlineOne4>
                        CHARTES
                      </Module03HeadlineOne4>
                    </Wrapper>
                </Module03TextWrapper>
                </Module03Wrapper>
              </Col>
            </Row>

          </Container>
        </BackgroundLarge>
      </MediaQuery>

      {/* SMALL */}

      <MediaQuery minWidth={1} maxWidth={800}>
        <BackgroundLarge>
          <Container fluid>

            <Row align="start" justify="start" style={{ height: '14vw' }}>
            </Row>

            <div ref={ref}>
            {isVisible
              ? handleWaypointEnter()
              : ''
            }
            </div>

            <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
              <Col sm={12}>
                <Module03Wrapper>
                  <Module03Photo style={{ height: '50vw', width: '36vw', marginLeft: '37vw' }}></Module03Photo>
                  <Module03TextWrapper>
                  <Wrapper style={{ height: '14vw' }}>
                    <Module03HeadlineOne1 style={{ fontSize: '17vw' }}>
                      BETWEEN
                    </Module03HeadlineOne1>
                  </Wrapper>
                  <Wrapper style={{ height: '14vw' }}>
                    <Module03HeadlineOne2 style={{ fontSize: '17vw' }}>
                      THE HOURS
                    </Module03HeadlineOne2>
                  </Wrapper>
                  <Module03HeadlineWrapper>
                    <TitleLarge2s>The</TitleLarge2s>
                    <TitleLarge5s>CALM SHADOWS</TitleLarge5s>
                    <TitleLarge3s>In Praise</TitleLarge3s>
                      <br></br>
                    <TitleLarge4s>of  </TitleLarge4s>
                    <TitleLarge3s>Shadows</TitleLarge3s>
                  </Module03HeadlineWrapper>
                  <Wrapper style={{ height: '14vw' }}>
                    <Module03HeadlineOne3 style={{ fontSize: '17vw' }}>
                      STEPILE
                    </Module03HeadlineOne3>
                  </Wrapper>
                  <Wrapper style={{ height: '14vw' }}>
                    <Module03HeadlineOne4 style={{ fontSize: '17vw' }}>
                      CHARTES
                    </Module03HeadlineOne4>
                  </Wrapper>
                </Module03TextWrapper>
                </Module03Wrapper>
              </Col>
            </Row>



          </Container>
        </BackgroundLarge>
      </MediaQuery>

    </div>
  )
}

export default Module03;
