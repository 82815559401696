import React, { Component } from 'react'
import * as THREE from 'three'
import { Clock, AmbientLight, DirectionalLight } from "three";
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { Waypoint } from 'react-waypoint';
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'

import { BigHeadlineWrapper } from './module_04.style'
import { BigHeadlineOne } from './module_04.style'
import { BigHeadlineTwo } from './module_04.style'
import { BigHeadlineThree } from './module_04.style'
import { BigHeadlineWrapperMargin } from './module_04.style'
import { AdTextWrapper } from './module_04.style'
import { BigHeadlineTwoMargin } from './module_04.style'

import { Wrapper } from './module_04.style';
import { WrapperInline } from './module_04.style';
import { TitleLarge2 } from './module_04.style';
import { TitleLarge3 } from './module_04.style';
import { TitleLarge4 } from './module_04.style';
import { TitleLarge5 } from './module_04.style';
import { BackgroundLarge } from './module_04.style';

import { TitleLarge2s } from './module_04.style';
import { TitleLarge3s } from './module_04.style'
import { TitleLarge4s } from './module_04.style'
import { TitleLarge5s } from './module_04.style'
import { TitleLarge6s } from './module_04.style'

import { HeadPhoto } from './module_04.style'
import Cloth from './cloth/models/cloth';
import ClothView from './cloth/views/cloth';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {


  TweenMax.to(
    document.querySelector( String("."+BigHeadlineOne.styledComponentId) ),
    1.4,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.3
  })
  TweenMax.to(
    document.querySelector( String("."+BigHeadlineTwoMargin.styledComponentId) ),
    1.4,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.6
  })
  TweenMax.to(
    document.querySelector( String("."+BigHeadlineThree.styledComponentId) ),
    1.4,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.9
  })
}

class Module04 extends Component {

   componentDidMount() {

      const width = this.mount.clientWidth
      const height = this.mount.clientHeight

      this.mouse = new THREE.Vector2()

      // ADD SCENE
      this.scene = new THREE.Scene()

      // ADD CAMERA
      this.camera = new THREE.PerspectiveCamera(
        30, width / height, 1, 10000
      );
      this.camera.position.x = 1000;
      this.camera.position.y = 50;
      this.camera.position.z = 1500;

      // CAMERA START POSITION
      //this.camera.position.x = 1000;
      //this.camera.position.y = 50;
      //this.camera.position.z = 1500;
      // TweenMax.to(this.camera.position, 5, {z: 15, ease: Expo.easeOut, delay: 2.3})

      // ADD RENDERER
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      this.renderer.setClearColor('#ffcc00',0)
      this.renderer.setSize( width, height )
      this.renderer.setPixelRatio( window.devicePixelRatio )
      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.mount.appendChild( this.renderer.domElement )
      this.clock = new Clock()

      this.ambientLight = new THREE.AmbientLight("#ffffff", 1.2)
      this.scene.add( this.ambientLight )

      this.cloth = new Cloth()
      this.clothView = new ClothView( this.cloth )
      this.scene.add( this.clothView.mesh )

      var geometry = new THREE.BoxGeometry(50,50,50);
      var texture = new THREE.MeshLambertMaterial({ color:0x00ff00 });
      this.cube = new THREE.Mesh( geometry, texture );
      this.scene.add( this.cube )
      this.rot = 0

      //this.camera.lookAt( this.clothView.mesh )

      this.start()
      handleWaypointEnter()
   }

   componentWillUnmount() {
      this.stop()
      this.mount.removeChild(this.renderer.domElement)
   }

   start = () => {
      if (!this.frameId) {
           this.frameId = requestAnimationFrame(this.animate)
      }
   }

   stop = () => {
      cancelAnimationFrame(this.frameId)
   }

   animate = () => {

      let time = Date.now()
      this.cloth.simulate(time, this.clothView.geometry)
      this.clothView.update()

      this.rot ++
      this.cube.rotation.x = this.rot/20

      //this.camera.lookAt( this.cube )
      this.camera.lookAt(new THREE.Vector3(0,0,0))

      this.frameId = window.requestAnimationFrame(this.animate)
      this.renderScene()
   }

   renderScene = () => {
      this.renderer.render(this.scene, this.camera)
   }

   render() {
     return (

       <div>

          {/* LARGE */}

          <MediaQuery minWidth={1200}>
            <BackgroundLarge>
              <Container fluid>

                <Row
                  align="start"
                  justify="start"
                  style={{
                    height: '10vw'
                  }}>

                  <div></div>
                </Row>

                <Row align="start" justify="start" >
                    <HeadPhoto
                      style={{
                        width: '100%',
                        height: '100vh',
                        background: '#BEAB7A',
                        backgroundSize: 'cover'
                      }}
                      ref={(mount) => { this.mount = mount }}
                    />
                </Row>

                <Row
                  align="start"
                  justify="start"
                  style={{
                    height: '100vh',
                    paddingBottom: '6px',
                    marginTop: '-6vw'
                  }}>

                  <Col sm={12}>


                    <BigHeadlineWrapperMargin>

                      <Wrapper>
                        <BigHeadlineOne>OBSCURE</BigHeadlineOne>
                      </Wrapper>

                      <WrapperInline>
                        <BigHeadlineTwoMargin>OF</BigHeadlineTwoMargin>
                      </WrapperInline>

                      <AdTextWrapper style={{ verticalAlign: 'top' }}>
                        <TitleLarge2 style={{ marginTop: '-10px' }}>The</TitleLarge2>
                        <TitleLarge5>CALM SHADOWS</TitleLarge5>
                        <TitleLarge3>In Praise</TitleLarge3>
                          <br></br>
                        <TitleLarge4>of</TitleLarge4>
                        <TitleLarge3>Shadows</TitleLarge3>
                      </AdTextWrapper>

                      <Wrapper>
                        <BigHeadlineThree>PLACES</BigHeadlineThree>
                      </Wrapper>

                    </BigHeadlineWrapperMargin>
                  </Col>
                </Row>

                <Row
                  align="start"
                  justify="start"
                  style={{
                    height: '1vw'
                  }}>

                  <div></div>
                </Row>

              </Container>
            </BackgroundLarge>
          </MediaQuery>

          {/* MEDIUM */}

          <MediaQuery minWidth={801} maxWidth={1199}>
            <BackgroundLarge>
              <Container fluid>

                <Row align="start" justify="start" style={{ height: '4vw' }}>
                  <div></div>
                </Row>

                <Row align="start" justify="start" >
                  <div></div>
                </Row>

                <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px', marginTop: '-7vw' }}>
                  <Col sm={12}>

                    <BigHeadlineWrapperMargin>
                      <Wrapper><BigHeadlineOne>OBSCURE</BigHeadlineOne></Wrapper>
                      <WrapperInline><BigHeadlineTwoMargin>OF</BigHeadlineTwoMargin></WrapperInline>
                      <AdTextWrapper>
                        <TitleLarge2>The</TitleLarge2>
                        <TitleLarge5>CALM SHADOWS</TitleLarge5>
                        <TitleLarge3>In Praise</TitleLarge3>
                          <br></br>
                        <TitleLarge4>of</TitleLarge4>
                        <TitleLarge3>Shadows</TitleLarge3>
                      </AdTextWrapper>
                      <Wrapper><BigHeadlineThree>PLACES</BigHeadlineThree></Wrapper>
                    </BigHeadlineWrapperMargin>
                  </Col>
                </Row>

                <Row align="start" justify="start" style={{ height: '2vw' }}>
                  <div></div>
                </Row>

              </Container>
            </BackgroundLarge>
          </MediaQuery>

          {/* SMALL */}

          <MediaQuery minWidth={1} maxWidth={800}>
            <BackgroundLarge>
              <Container fluid>

                <Row align="start" justify="start" style={{ height: '3vw' }}>
                  <div></div>
                </Row>

                <Row align="start" justify="start" >
                  <div></div>
                </Row>

                <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px', marginTop: '-10vw' }}>
                  <Col sm={12}>

                    <BigHeadlineWrapperMargin>
                      <Wrapper><BigHeadlineOne>OBSCURE</BigHeadlineOne></Wrapper>
                      <WrapperInline><BigHeadlineTwoMargin>OF</BigHeadlineTwoMargin></WrapperInline>
                      <AdTextWrapper style={{ verticalAlign: 'top' }}>
                        <TitleLarge2s style={{ marginTop: '-10px' }}>The</TitleLarge2s>
                        <TitleLarge5s>CALM SHADOWS</TitleLarge5s>
                        <TitleLarge3s>In Praise</TitleLarge3s>
                          <br></br>
                        <TitleLarge4s>of  </TitleLarge4s>
                        <TitleLarge3s>Shadows</TitleLarge3s>
                      </AdTextWrapper>
                      <Wrapper><BigHeadlineThree>PLACES</BigHeadlineThree></Wrapper>
                    </BigHeadlineWrapperMargin>
                  </Col>
                </Row>

                <Row align="start" justify="start" style={{ height: '12vw' }}>
                  <div></div>
                </Row>

              </Container>
            </BackgroundLarge>
          </MediaQuery>

        </div>
    )
  }
}

export default Module04;
