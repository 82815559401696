import styled from 'styled-components'

export const BackgroundLarge = styled.div`
  background-color: #EDEDED;
`

export const HeadlineOne = styled.div`
  font-size: 70px;
  color: #1F2023;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const HeadlineTwo = styled.div`
  font-size: 70px;
  color: #1F2023;
  margin-left: 34vh;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const HeadlineThree = styled.div`
  font-size: 70px;
  color: #1F2023;
  line-height: 75%;
  user-select: none;
  transform: translate(0,100%);
`

export const Wrapper = styled.div`
  overflow: hidden;
`

export const HeadlineWrapper = styled.div`
  position: absolute;
  margin-top: 2vh;
  left: 1vh;
`
