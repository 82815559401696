import styled from 'styled-components';


export const Content = styled.div`
  position: absolute;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
`
//overflow: hidden;
export const Nav = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  mix-blend-mode: lighten;
  pointer-events: none;
`
