import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { BackgroundMedium } from './About.style';
import { TextLarge } from './About.style';
import { TextLargeClean } from './About.style';
import { TitleLarge } from './About.style';
import { LetterOne } from './About.style';
import { LetterTwo } from './About.style';
import { LetterThree } from './About.style';
import { LogoTiny } from './About.style';
import { HeadlineLarge } from './About.style';
import { HeadlineLargeBottom } from './About.style';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const AboutMedium = () => (

      <BackgroundMedium>
        <Container fluid>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}></Col>
            <Col sm={9} style={{ height: '25vw', display: 'flex' }}><HeadlineLargeBottom>We Inspire Progress</HeadlineLargeBottom></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={12}><HeadlineLarge>We Inspire Progress</HeadlineLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={3}></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={6}></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={6}></Col>
            <Col sm={6}><HeadlineLarge>Business Artistry</HeadlineLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}></Col>
            <Col sm={9}><HeadlineLarge>This is the foundation of<br></br>Business Artistry.</HeadlineLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
        </Container>
      </BackgroundMedium>

)

export default AboutMedium;
