import styled from 'styled-components'


export const ViewButton = styled.div`
  background: url(${props => props.bg}) no-repeat;
  background-position: center;
  width: 40px;
  height: 45px;
  margin-bottom: 4px;
  opacity: .7;
  transform: translate(200%,0);
`

export const NavigationWrapper = styled.div`
  position: absolute;
  right: 60px;
  height: 100vh;
  user-select: none;
`

export const ViewNavigationWrapper = styled.div`
  position: absolute;
  margin-top: 50vh;
  transform: translate(0,-50%);
  user-select: none;
`

export const MainMenuButton = styled.div`
  background: url(${props => props.bg}) no-repeat;
  background-position: center;
  width: 40px;
  height: 45px;
  top: 20px;
  opacity: .7;
  transform: translate(200%,0);
  position: absolute;
  mix-blend-mode: difference;
`

export const SoundButton = styled.div`
  background: url(${props => props.bg}) no-repeat;
  background-position: center;
  width: 40px;
  height: 45px;
  bottom: 20px;
  opacity: .7;
  transform: translate(200%,0);
  position: absolute;
`

// Main Menu

export const MainMenuWrapper = styled.div`
  position: absolute;
  left: 60px;
  width: 400px;
  height: 100vh;
  background-color: #F6F6F6;
  text-align: center;
  mix-blend-mode: lighten;
  transform: translate(100%,0);
`


export const TitleLarge2 = styled.p`
  margin: 0px;
  margin-top: 10vh;
  font-family: 'reason02';
  font-size: 8px;
  letter-spacing: 0px;
  line-height: 45%;
  color: #000;
`

export const TitleLarge3 = styled.p`
  display: inline-block;
  margin: 0px;
  font-family: 'reason03';
  font-size: 35px;
  letter-spacing: 1px;
  line-height: 90%;
  color: #000;
`

export const TitleLarge4 = styled.p`
  display: inline-block;
  margin: 0px;
  margin-right: 10px;
  font-family: 'reason04';
  font-size: 35px;
  letter-spacing: 0px;
  line-height: 90%;
  color: #000;
`

export const TitleLarge5 = styled.p`
  margin-top: 10px;
  margin-bottom: 2vw;
  font-family: 'reason05';
  font-size: 7px;
  letter-spacing: 3px;
  line-height: 55%;
  color: #000;
`

export const TitleLarge6 = styled.p`
  margin-top: 24px;
  font-family: 'reason01';
  font-size: 8px;
  letter-spacing: 3px;
  line-height: 55%;
  color: #000;
`

export const MenuItem = styled.p`
  margin-top: 10px;
  margin-bottom: 2vw;
  font-family: 'reason05';
  font-size: 7px;
  letter-spacing: 2px;
  line-height: 55%;
  color: #000;
`

export const MenuLine = styled.p`
  border-left: .7px solid #D8D8D8;
  margin-left: 50%;
  margin-bottom: 3vh;
  margin-top: 3vh;
  height: 8vh;
  opacity: .8;
`

export const MenuSide = styled.div`
  background: url(${props => props.bg}) no-repeat;
  background-position: right;
  background-size: contain;
  width: 443px;
  height: 100vh;
  transform: translate(-100%,0);
  position: absolute;
  user-select: none;
`
