import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { useInView } from 'react-hook-inview'
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'

import { Wrapper } from './module_05.style';
import { Module05Wrapper } from './module_05.style'
import { Module05HeadlineOne } from './module_05.style'
import { Module05HeadlineOne2 } from './module_05.style'
import { Module05HeadlineTwo } from './module_05.style'
import { Module05HeadlineThree } from './module_05.style'
import { Module05HeadlineWrapper } from './module_05.style'
import { Module05Photo } from './module_05.style'
import { Module05TextWrapper } from './module_05.style'

import { TitleLarge2 } from './module_05.style';
import { TitleLarge3 } from './module_05.style';
import { TitleLarge4 } from './module_05.style';
import { TitleLarge5 } from './module_05.style';
import { BackgroundLarge } from './module_05.style';

import { TitleLarge2s } from './module_05.style';
import { TitleLarge3s } from './module_05.style'
import { TitleLarge4s } from './module_05.style'
import { TitleLarge5s } from './module_05.style'
import { TitleLarge6s } from './module_05.style'

import { TitleLarge2B } from './module_05.style';
import { TitleLarge3B } from './module_05.style'
import { TitleLarge4B } from './module_05.style'
import { TitleLarge5B } from './module_05.style'


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {


  TweenMax.to(
    document.querySelector( String("."+Module05HeadlineOne.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.2
  })
  TweenMax.to(
    document.querySelector( String("."+Module05HeadlineOne2.styledComponentId) ),
    1.2,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.4
  })
}

const Module05   = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (


  <div>

    {/* LARGE */}

    <MediaQuery minWidth={1200}>
      <BackgroundLarge>
        <Container fluid>

        <div ref={ref}>
        {isVisible
          ? handleWaypointEnter()
          : ''
        }
        </div>

          <Row align="start" justify="start" style={{ height: '90vw', paddingBottom: '6px' }}>
            <Col sm={12}>
              <Module05Wrapper>
                <Module05Photo></Module05Photo>
                <Module05TextWrapper>
                  <Wrapper style={{ marginLeft: '17.5vw' }}><Module05HeadlineOne >PLACES</Module05HeadlineOne></Wrapper>
                  <Wrapper style={{ marginLeft: '5vw' }}><Module05HeadlineOne2>OBSCURE</Module05HeadlineOne2></Wrapper>
                  <Module05HeadlineWrapper>
                    <TitleLarge2B>The</TitleLarge2B>
                    <TitleLarge5B>CALM SHADOWS</TitleLarge5B>
                    <TitleLarge3B>In Praise</TitleLarge3B>
                      <br></br>
                    <TitleLarge4B>of  </TitleLarge4B>
                    <TitleLarge3B>Shadows</TitleLarge3B>
                  </Module05HeadlineWrapper>
              </Module05TextWrapper>
              </Module05Wrapper>
            </Col>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* MEDIUM */}

    <MediaQuery minWidth={801} maxWidth={1199}>
      <BackgroundLarge>
        <Container fluid>

        <div ref={ref}>
        {isVisible
          ? handleWaypointEnter()
          : ''
        }
        </div>

          <Row align="start" justify="start" style={{ height: '90vw', paddingBottom: '6px' }}>
            <Col sm={12}>
              <Module05Wrapper>
                <Module05Photo></Module05Photo>
                <Module05TextWrapper>
                  <Wrapper style={{ marginLeft: '17.5vw' }}><Module05HeadlineOne >PLACES</Module05HeadlineOne></Wrapper>
                  <Wrapper style={{ marginLeft: '5vw' }}><Module05HeadlineOne2>OBSCURE</Module05HeadlineOne2></Wrapper>
                  <Module05HeadlineWrapper>
                    <TitleLarge2>The</TitleLarge2>
                    <TitleLarge5>CALM SHADOWS</TitleLarge5>
                    <TitleLarge3>In Praise</TitleLarge3>
                      <br></br>
                    <TitleLarge4>of</TitleLarge4>
                    <TitleLarge3>Shadows</TitleLarge3>
                  </Module05HeadlineWrapper>
              </Module05TextWrapper>
              </Module05Wrapper>
            </Col>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* SMALL */}

    <MediaQuery minWidth={1} maxWidth={800}>
      <BackgroundLarge>
        <Container fluid>

        <div ref={ref}>
        {isVisible
          ? handleWaypointEnter()
          : ''
        }
        </div>

          <Row align="start" justify="start" style={{ height: '130vw', paddingBottom: '6px' }}>
            <Col sm={12}>
              <Module05Wrapper>
                <Module05Photo></Module05Photo>
                <Module05TextWrapper>
                  <Wrapper style={{ marginLeft: '30vw', width: '130vw', height: '22vw' }}>
                    <Module05HeadlineOne style={{ fontSize: '23vw' }}>
                      PLACES
                    </Module05HeadlineOne>
                  </Wrapper>
                  <Wrapper style={{ marginLeft: '12vw', width: '130vw', height: '22vw' }}>
                    <Module05HeadlineOne2 style={{ fontSize: '23vw' }}>
                      OBSCURE
                    </Module05HeadlineOne2>
                  </Wrapper>
                  <Module05HeadlineWrapper style={{ marginLeft: '10vw', verticalAlign: 'bottom' }}>
                    <TitleLarge2s>The</TitleLarge2s>
                    <TitleLarge5s>CALM SHADOWS</TitleLarge5s>
                    <TitleLarge3s>In Praise</TitleLarge3s>
                      <br></br>
                    <TitleLarge4s>of  </TitleLarge4s>
                    <TitleLarge3s>Shadows</TitleLarge3s>
                  </Module05HeadlineWrapper>
              </Module05TextWrapper>
              </Module05Wrapper>
            </Col>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

  </div>
)
}

export default Module05;
