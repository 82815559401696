import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { useInView } from 'react-hook-inview'
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'

import { BigHeadlineWrapper } from './module_04.style'
import { BigHeadlineOne } from './module_04.style'
import { BigHeadlineTwo } from './module_04.style'
import { BigHeadlineThree } from './module_04.style'
import { BigHeadlineWrapperMargin } from './module_04.style'
import { AdTextWrapper } from './module_04.style'
import { BigHeadlineTwoMargin } from './module_04.style'

import { Wrapper } from './module_04.style';
import { WrapperInline } from './module_04.style';
import { TitleLarge2 } from './module_04.style';
import { TitleLarge3 } from './module_04.style';
import { TitleLarge4 } from './module_04.style';
import { TitleLarge5 } from './module_04.style';
import { BackgroundLarge } from './module_04.style';

import { TitleLarge2s } from './module_04.style';
import { TitleLarge3s } from './module_04.style'
import { TitleLarge4s } from './module_04.style'
import { TitleLarge5s } from './module_04.style'
import { TitleLarge6s } from './module_04.style'

import { TitleLarge2B } from './module_04.style';
import { TitleLarge3B } from './module_04.style'
import { TitleLarge4B } from './module_04.style'
import { TitleLarge5B } from './module_04.style'


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {


  TweenMax.to(
    document.querySelector( String("."+BigHeadlineOne.styledComponentId) ),
    1.4,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.4
  })
  TweenMax.to(
    document.querySelector( String("."+BigHeadlineTwoMargin.styledComponentId) ),
    1.4,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.6
  })
  TweenMax.to(
    document.querySelector( String("."+BigHeadlineThree.styledComponentId) ),
    1.4,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.8
  })
}


const Module04   = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (


  <div>

    {/* LARGE */}

    <MediaQuery minWidth={1200}>
      <BackgroundLarge>
        <Container fluid>

          <Row align="start" justify="start" style={{ height: '14vw' }}>
          </Row>

          <div ref={ref}>
          {isVisible
            ? handleWaypointEnter()
            : ''
          }
          </div>

          <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
            <Col sm={12}>
              <BigHeadlineWrapperMargin>
                <Wrapper>
                  <BigHeadlineOne>
                    OBSCURE
                  </BigHeadlineOne>
                </Wrapper>
                <WrapperInline>
                  <BigHeadlineTwoMargin>
                    OF
                  </BigHeadlineTwoMargin>
                </WrapperInline>
                <AdTextWrapper>
                  <TitleLarge2B>The</TitleLarge2B>
                  <TitleLarge5B>CALM SHADOWS</TitleLarge5B>
                  <TitleLarge3B>In Praise</TitleLarge3B>
                    <br></br>
                  <TitleLarge4B>of  </TitleLarge4B>
                  <TitleLarge3B>Shadows</TitleLarge3B>
                </AdTextWrapper>
                <Wrapper>
                  <BigHeadlineThree>
                    PLACES
                  </BigHeadlineThree>
                </Wrapper>
              </BigHeadlineWrapperMargin>
            </Col>
          </Row>



          <Row align="start" justify="start" style={{ height: '14vw' }}>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* MEDIUM */}

    <MediaQuery minWidth={801} maxWidth={1199}>
      <BackgroundLarge>
        <Container fluid>

          <Row align="start" justify="start" style={{ height: '14vw' }}>
          </Row>

          <div ref={ref}>
          {isVisible
            ? handleWaypointEnter()
            : ''
          }
          </div>

          <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
            <Col sm={12}>
              <BigHeadlineWrapperMargin>
                <Wrapper>
                  <BigHeadlineOne>
                    OBSCURE
                  </BigHeadlineOne>
                </Wrapper>
                <WrapperInline>
                  <BigHeadlineTwoMargin>
                    OF
                  </BigHeadlineTwoMargin>
                </WrapperInline>
                <AdTextWrapper>
                  <TitleLarge2>The</TitleLarge2>
                  <TitleLarge5>CALM SHADOWS</TitleLarge5>
                  <TitleLarge3>In Praise</TitleLarge3>
                    <br></br>
                  <TitleLarge4>of</TitleLarge4>
                  <TitleLarge3>Shadows</TitleLarge3>
                </AdTextWrapper>
                <Wrapper>
                  <BigHeadlineThree>
                    PLACES
                  </BigHeadlineThree>
                </Wrapper>
              </BigHeadlineWrapperMargin>
            </Col>
          </Row>

          <Row align="start" justify="start" style={{ height: '14vw' }}>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

    {/* SMALL */}

    <MediaQuery minWidth={1} maxWidth={800}>
      <BackgroundLarge>
        <Container fluid>

          <Row align="start" justify="start" style={{ height: '14vw' }}>
          </Row>

          <div ref={ref}>
          {isVisible
            ? handleWaypointEnter()
            : ''
          }
          </div>

          <Row align="start" justify="start" style={{ height: '100vh', paddingBottom: '6px' }}>
            <Col sm={12}>
              <BigHeadlineWrapperMargin>
                <Wrapper>
                  <BigHeadlineOne>
                    OBSCURE
                  </BigHeadlineOne>
                </Wrapper>
                <WrapperInline>
                  <BigHeadlineTwoMargin>
                    OF
                  </BigHeadlineTwoMargin>
                </WrapperInline>
                <AdTextWrapper style={{ verticalAlign: 'top' }}>
                  <TitleLarge2s style={{ marginTop: '-10px' }}>The</TitleLarge2s>
                  <TitleLarge5s>CALM SHADOWS</TitleLarge5s>
                  <TitleLarge3s>In Praise</TitleLarge3s>
                    <br></br>
                  <TitleLarge4s>of  </TitleLarge4s>
                  <TitleLarge3s>Shadows</TitleLarge3s>
                </AdTextWrapper>
                <Wrapper>
                  <BigHeadlineThree>
                    PLACES
                  </BigHeadlineThree>
                </Wrapper>
              </BigHeadlineWrapperMargin>
            </Col>
          </Row>

          <Row align="start" justify="start" style={{ height: '32vw' }}>
          </Row>

        </Container>
      </BackgroundLarge>
    </MediaQuery>

  </div>
)
}

export default Module04;
