import React from 'react';
import MediaQuery from 'react-responsive';
import ProjectLarge from './Project/ProjectLarge'


const Project = (id) => (

    <div>
      <ProjectLarge
        id = {id.id}
      />
    </div>
)

export default Project;
