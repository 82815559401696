import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'
import { useInView } from 'react-hook-inview'

import { TitleLargel } from './module_02.style';
import { TitleLargeBl } from './module_02.style';

import { TitleLarges } from './module_02.style';
import { TitleLargeBs } from './module_02.style';

import { TitleLarge2s } from './module_02.style';
import { TitleLarge3s } from './module_02.style'
import { TitleLarge4s } from './module_02.style'
import { TitleLarge5s } from './module_02.style'
import { TitleLarge6s } from './module_02.style'

import { TitleLarge2l } from './module_02.style';
import { TitleLarge3l } from './module_02.style'
import { TitleLarge4l } from './module_02.style'
import { TitleLarge5l } from './module_02.style'
import { TitleLarge6l } from './module_02.style'

import { Wrapper } from './module_02.style';
import { TextLarge } from './module_02.style';
import { TitleLarge } from './module_02.style';
import { TitleLargeB } from './module_02.style';
import { TitleLarge2 } from './module_02.style';
import { TitleLarge3 } from './module_02.style'
import { TitleLarge4 } from './module_02.style'
import { TitleLarge5 } from './module_02.style'
import { TitleLarge6 } from './module_02.style'

import { WrapperBig } from './module_02.style';
import { TitleLargeTop } from './module_02.style';
import { TitleLargeBottom } from './module_02.style';
import { TitleLarge2B } from './module_02.style';
import { TitleLarge3B } from './module_02.style'
import { TitleLarge4B } from './module_02.style'
import { TitleLarge5B } from './module_02.style'
import { TitleLarge6B } from './module_02.style'
import { BackgroundLarge } from './module_02.style'



setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {


  TweenMax.to(
    document.querySelector( String("."+TitleLarge.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.4
  })
  TweenMax.to(
    document.querySelector( String("."+TitleLargeB.styledComponentId) ),
    1,
  {
    y:"0px",
    ease:Expo.easeOut,
    delay:.6
  })

}


const Module02a = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (

    <div>

    {/* EXTRA LARGE */}

    <MediaQuery minWidth={1500}>
        <BackgroundLarge>
          <Container fluid>

            <Row align="start" justify="start" style={{ height: '150px' }}>
            </Row>

            <div ref={ref}>
            {isVisible
              ? handleWaypointEnter()
              : ''
            }
            </div>

            <Row align="start" justify="start" style={{ height: '200px' }}>
              <Col sm={2}>
                <TitleLarge6B>01</TitleLarge6B>
                <TitleLarge2B>The</TitleLarge2B>
                <TitleLarge5B>CALM SHADOWS</TitleLarge5B>
                <TitleLarge3B>In Praise</TitleLarge3B>
                  <br></br>
                <TitleLarge4B>of  </TitleLarge4B>
                <TitleLarge3B>Shadows</TitleLarge3B>
              </Col>
              <Col sm={5}>
                <WrapperBig>
                  <TitleLarge style={{ fontSize: '230px' }}>EMBRACE</TitleLarge>
                </WrapperBig>
                <WrapperBig>
                  <TitleLargeB style={{ fontSize: '230px' }}>CHANGE</TitleLargeB>
                </WrapperBig>
              </Col>
              <Col sm={1}></Col>
              <Col sm={2}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
              <Col sm={2}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            </Row>

          </Container>
        </BackgroundLarge>
      </MediaQuery>

      {/* LARGE */}

      <MediaQuery minWidth={1200} maxWidth={1499}>
          <BackgroundLarge>
            <Container fluid>

              <Row align="start" justify="start" style={{ height: '7vw' }}>
              </Row>

              <div ref={ref}>
              {isVisible
                ? handleWaypointEnter()
                : ''
              }
              </div>

              <Row align="start" justify="start" style={{ height: '16vw' }}>
                <Col sm={2}>
                  <TitleLarge6>01</TitleLarge6>
                  <TitleLarge2>The</TitleLarge2>
                  <TitleLarge5>CALM SHADOWS</TitleLarge5>
                  <TitleLarge3>In Praise</TitleLarge3>
                    <br></br>
                  <TitleLarge4>of  </TitleLarge4>
                  <TitleLarge3>Shadows</TitleLarge3>
                </Col>
                <Col sm={5}>
                  <Wrapper>
                    <TitleLarge>EMBRACE</TitleLarge>
                  </Wrapper>
                  <Wrapper>
                    <TitleLargeB>CHANGE</TitleLargeB>
                  </Wrapper>
                </Col>
                <Col sm={1}></Col>
                <Col sm={2}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
                <Col sm={2}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
              </Row>
            </Container>
          </BackgroundLarge>
        </MediaQuery>

        {/* MEDIUM */}

        <MediaQuery minWidth={801} maxWidth={1199}>
          <BackgroundLarge>
            <Container fluid>

              <Row align="start" justify="start" style={{ height: '10vw' }}>
              </Row>

              <div ref={ref}>
              {isVisible
                ? handleWaypointEnter()
                : ''
              }
              </div>

              <Row align="start" justify="start" style={{ height: '16.66vw' }}>
                <Col sm={2}>
                  <TitleLarge6>01</TitleLarge6>
                  <TitleLarge2>The</TitleLarge2>
                  <TitleLarge5>CALM SHADOWS</TitleLarge5>
                  <TitleLarge3>In Praise</TitleLarge3>
                    <br></br>
                  <TitleLarge4>of  </TitleLarge4>
                  <TitleLarge3>Shadows</TitleLarge3>
                </Col>
                <Col sm={5}>
                  <Wrapper>
                    <TitleLarge>EMBRACE</TitleLarge>
                  </Wrapper>
                  <Wrapper>
                    <TitleLargeB>CHANGE</TitleLargeB>
                  </Wrapper>
                </Col>
                <Col sm={1}></Col>
                <Col sm={2}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
                <Col sm={2}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
              </Row>

            </Container>
          </BackgroundLarge>
        </MediaQuery>

        {/* SMALL */}

        <MediaQuery minWidth={1} maxWidth={800}>
          <BackgroundLarge>
            <Container fluid>

              <Row align="start" justify="start" style={{ height: '10vw' }}>
              </Row>

              <div ref={ref}>
              {isVisible
                ? handleWaypointEnter()
                : ''
              }
              </div>

              <Row align="start" justify="start" style={{ marginBottom: '12vw' }}>
                <Col sm={6}>
                  <TitleLarge6s>01</TitleLarge6s>
                </Col>
                <Col sm={6}>
                  <TitleLarge2s>The</TitleLarge2s>
                  <TitleLarge5s>CALM SHADOWS</TitleLarge5s>
                  <TitleLarge3s>In Praise</TitleLarge3s>
                    <br></br>
                  <TitleLarge4s>of  </TitleLarge4s>
                  <TitleLarge3s>Shadows</TitleLarge3s>
                </Col>

              </Row>
              <Row align="start" justify="start" style={{ height: '42vw' }}>
                <Col sm={12} >
                  <Wrapper style={{ height: '16.2vw' }}>
                    <TitleLarge style={{ fontSize: '18vw' }}>EMBRACE</TitleLarge>
                  </Wrapper>
                  <Wrapper style={{ height: '16.2vw' }}>
                    <TitleLargeB  style={{ fontSize: '18vw' }}>CHANGE</TitleLargeB>
                  </Wrapper>
                </Col>
              </Row>
              <Row align="start" justify="start" style={{ marginBottom: '16vw' }}>
                <Col sm={6}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
                <Col sm={6}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
              </Row>

            </Container>
          </BackgroundLarge>
        </MediaQuery>

      </div>
  )
}

export default Module02a;
