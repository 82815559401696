import React from 'react';
import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { useInView } from 'react-hook-inview'
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'

import { Wrapper } from './module_03.style';
import { PhotoOne } from './module_03.style';
import { PhotoTwo } from './module_03.style';
import { TextLarge } from './module_03.style';
import { TitleLarge } from './module_03.style';
import { TitleLargeB } from './module_03.style';
import { TitleLarge2 } from './module_03.style';
import { TitleLarge3 } from './module_03.style'
import { TitleLarge4 } from './module_03.style'
import { TitleLarge5 } from './module_03.style'
import { TitleLarge6 } from './module_03.style'
import { BackgroundLarge } from './module_03.style'

import { TitleLarge2s } from './module_03.style';
import { TitleLarge3s } from './module_03.style'
import { TitleLarge4s } from './module_03.style'
import { TitleLarge5s } from './module_03.style'

import { TitleLarge2l } from './module_03.style';
import { TitleLarge3l } from './module_03.style'
import { TitleLarge4l } from './module_03.style'
import { TitleLarge5l } from './module_03.style'




setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const handleWaypointEnter = () => {


  TweenMax.to(
    document.querySelector( String("."+PhotoOne.styledComponentId) ),
    1.8,
  {
    width:"100%",
    ease:Expo.easeOut,
    delay:.6
  })
  TweenMax.to(
    document.querySelector( String("."+PhotoTwo.styledComponentId) ),
    1.8,
  {
    height:"100%",
    ease:Expo.easeOut,
    delay:.2
  })


}


const Module03  = () => {

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  return (

    <div>

      {/* LARGE */}

      <MediaQuery minWidth={1200}>
          <BackgroundLarge>

            <Container fluid>

              <div ref={ref}>
                {isVisible
                  ? handleWaypointEnter()
                  : ''
                }
              </div>

              <Row align="start" justify="start" style={{ height: '16vw' }}>
              </Row>

              <Row align="start" justify="start" style={{ height: '33vw', marginBottom: '1px' }}>
                <Col sm={4} style={{ height: '33vw' }}>
                  <NavLink to='/project/2'>
                    <PhotoTwo/>
                  </NavLink>
                </Col>
                <Col sm={2}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
                <Col sm={4}>

                </Col>
              </Row>

              <Row align="start" justify="start" style={{ height: '33vw' }}>

                <Col sm={8}>
                  <NavLink to='/project/2'>
                    <PhotoOne/>
                  </NavLink>
                </Col>
                <Col sm={2}>
                    <TitleLarge2l>The</TitleLarge2l>
                    <TitleLarge5l>CALM SHADOWS</TitleLarge5l>
                    <TitleLarge3l>In Praise</TitleLarge3l>
                        <br></br>
                    <TitleLarge4l>of  </TitleLarge4l>
                    <TitleLarge3l>Shadows</TitleLarge3l></Col>
                <Col sm={2}></Col>
              </Row>

            </Container>
          </BackgroundLarge>
        </MediaQuery>

        {/* MEDIUM */}

        <MediaQuery minWidth={801} maxWidth={1199}>
          <BackgroundLarge>

            <Container fluid>

              <div ref={ref}>
                {isVisible
                  ? handleWaypointEnter()
                  : ''
                }
              </div>

              <Row align="start" justify="start" style={{ height: '16vw' }}>
              </Row>

              <Row align="start" justify="start" style={{ height: '33vw', marginBottom: '1px' }}>
                <Col sm={4} style={{ height: '33vw' }}>
                  <NavLink to='/project/2'>
                    <PhotoTwo/>
                  </NavLink>
                </Col>
                <Col sm={4}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
                <Col sm={4}>

                </Col>
              </Row>

              <Row align="start" justify="start" style={{ height: '33vw' }}>

                <Col sm={8}>
                  <NavLink to='/project/2'>
                    <PhotoOne style={{ height: '33vw' }}/>
                  </NavLink>
                </Col>
                <Col sm={2}>
                    <TitleLarge2>The</TitleLarge2>
                    <TitleLarge5>CALM SHADOWS</TitleLarge5>
                    <TitleLarge3>In Praise</TitleLarge3>
                        <br></br>
                    <TitleLarge4>of  </TitleLarge4>
                    <TitleLarge3>Shadows</TitleLarge3></Col>
                <Col sm={2}></Col>
              </Row>

            </Container>
          </BackgroundLarge>
        </MediaQuery>

        {/* SMALL */}

        <MediaQuery minWidth={1} maxWidth={800}>
          <BackgroundLarge>

            <Container fluid>

              <div ref={ref}>
                {isVisible
                  ? handleWaypointEnter()
                  : ''
                }
              </div>

              <Row align="start" justify="start" style={{ height: '16vw' }}>
              </Row>

              <Row align="start" justify="start" style={{ height: '50vw', marginBottom: '1px' }}>
                <Col sm={6}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
                <Col sm={6} style={{ paddingLeft: '0px', paddingRight: '0px', height: '50vw' }}>
                  <NavLink to='/project/2'>
                    <PhotoTwo/>
                  </NavLink>
                </Col>
              </Row>

              <Row align="start" justify="start" style={{ height: '50vw' }}>
                <Col sm={12} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                  <NavLink to='/project/2'>
                    <PhotoOne style={{ height: '50vw' }}/>
                  </NavLink>
                </Col>
              </Row>

              <Row align="start" justify="start" style={{ height: '30vw' }}>
                <Col sm={6}>
                    <TitleLarge2s>The</TitleLarge2s>
                    <TitleLarge5s>CALM SHADOWS</TitleLarge5s>
                    <TitleLarge3s>In Praise</TitleLarge3s>
                        <br></br>
                    <TitleLarge4s>of  </TitleLarge4s>
                    <TitleLarge3s>Shadows</TitleLarge3s>
                </Col>
                <Col sm={6}></Col>
              </Row>

            </Container>
          </BackgroundLarge>
        </MediaQuery>

    </div>

  )
}

export default Module03;
