import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { BackgroundLarge } from './About.style';
import { TextXLarge } from './About.style';
import { TextXLargeClean } from './About.style';
import { TitleLarge } from './About.style';
import { NumberLarge } from './About.style';
import { LetterOne } from './About.style';
import { LetterTwo } from './About.style';
import { LetterThree } from './About.style';
import { LogoTiny } from './About.style';
import { HeadlineXLarge } from './About.style';
import { HeadlineXLargeBottom } from './About.style';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const AboutLarge = () => (

    <BackgroundLarge>
      <Container fluid>
        <Row align="start" justify="start" style={{ height: '10vw' }}>
          <Col sm={2}></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '2vw' }}>
          <Col sm={4}></Col>
          <Col sm={4}><NumberLarge data-scroll data-scroll-speed='2'>1</NumberLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '16.66vw' }}>
          <Col sm={4}></Col>
          <Col sm={8} style={{ height: '16.66vw', display: 'flex' }}><HeadlineXLargeBottom  data-scroll data-scroll-speed='1'>A perfect symbiosis of</HeadlineXLargeBottom></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '16.66vw' }}>
          <Col sm={2}></Col>
          <Col sm={10}><HeadlineXLarge data-scroll data-scroll-speed='1'>design and technology fueling<br/>the future of your brand</HeadlineXLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '16.66vw' }}>
          <Col sm={2}><TextXLarge>Who We Are</TextXLarge></Col>
          <Col sm={2}></Col>
          <Col sm={2} style={{paddingRight: '2px'}}><TextXLarge>Working in service or captivating experiences and products that your audience carse about. Working in service or captivating experiences and products that your audience carse about.</TextXLarge></Col>
          <Col sm={2} style={{paddingRight: '2px'}}><TextXLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextXLarge></Col>
          <Col sm={2} style={{paddingRight: '2px'}}><TextXLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextXLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '14vw' }}>
          <Col sm={2}></Col>
          <Col sm={2}><NumberLarge data-scroll data-scroll-speed='2'>2</NumberLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '10vw' }}>
          <Col sm={2}></Col>
          <Col sm={8}><HeadlineXLarge data-scroll data-scroll-speed='1'>Multidisciplinary capabilities</HeadlineXLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '16.66vw' }}>
          <Col sm={2}><TextXLarge>What We Do</TextXLarge></Col>
          <Col sm={2}></Col>
          <Col sm={2} style={{paddingRight: '2px'}}><TextXLarge>Our capabilities includes Strategy, Concept, Art Direction, Spatial Design, UX Design and Engineering. Design Research R&D Partnerships including bespoke trend reports, future concepts and prototypes. Product continuous development for services, tools and spatial infrastructure. the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextXLarge></Col>
          <Col sm={2} style={{paddingRight: '2px'}}><TextXLarge>From concept ideation and art direction, to prototyping and production. Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextXLarge></Col>
          <Col sm={2} style={{paddingRight: '2px'}}><TextXLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextXLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '10vw' }}>
          <Col sm={2}></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '14vw' }}>
          <Col sm={4}></Col>
          <Col sm={2}><NumberLarge data-scroll data-scroll-speed='2'>3</NumberLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '14vw' }}>
          <Col sm={4}></Col>
          <Col sm={8}><HeadlineXLarge  data-scroll data-scroll-speed='1'>Continuous colaboration<br></br>processes</HeadlineXLarge></Col>
        </Row>
        <Row align="start" justify="start" style={{ height: '16.66vw' }}>
          <Col sm={2}><TextXLarge>How We Do It</TextXLarge></Col>
          <Col sm={2}></Col>
          <Col sm={2} style={{paddingRight: '2px'}}><TextXLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextXLarge></Col>
          <Col sm={2} style={{paddingRight: '2px'}}><TextXLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextXLarge></Col>
          <Col sm={2} style={{paddingRight: '2px'}}><TextXLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextXLarge></Col>
        </Row>

        <Row align="start" justify="start" style={{ height: '16.66vw' }}>
          <Col sm={2}></Col>
        </Row>

        {/*  */}

        <div style={{ backgroundColor:'#1F1F1F', padding:'15px', margin:'-15px', zIndex: '-10'}}>
          <Row align="start" justify="start" style={{ height: '300px', marginTop: '100px' }}>
            <Col sm={4}></Col>
            <Col sm={2}><NumberLarge  data-scroll data-scroll-speed='1' style={{ color:'#F7F5EB', lineHeight:'100%'}}>4</NumberLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '150px' }}>
            <Col sm={2}><TextXLarge style={{ color:'#F7F5EB'}}>Contact Us</TextXLarge></Col>
            <Col sm={2}></Col>
            <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}>
              <TextXLarge style={{color:'#F7F5EB'}}>
                Bergen,<br/>
                Hordaland,<br/>
                Norway
              </TextXLarge>
            </Col>
            <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}>
              <TextXLarge style={{color:'#F7F5EB'}}>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>info@typed.systems</a>
              </TextXLarge>
            </Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '150px' }}>
            <Col sm={2}><TextXLarge style={{ color:'#F7F5EB'}}>Work with us</TextXLarge></Col>
            <Col sm={2}></Col>
            <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}>
              <TextXLarge style={{color:'#F7F5EB'}}>
                For new business enquiries contact<br/>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Darek Nyckowiak</a>
              </TextXLarge>
            </Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '150px' }}>
            <Col sm={2}><TextXLarge style={{ color:'#EDEDED'}}>Folllow Us</TextXLarge></Col>
            <Col sm={2}></Col>
            <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}>
              <TextXLarge style={{color:'#F7F5EB'}}>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Instagram</a><br/>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Twitter</a><br/>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Facebook</a><br/>
                <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>LinkedIn</a><br/>
              </TextXLarge>
            </Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '200px' }}>
            <Col sm={2}><TextXLarge style={{ color:'#F7F5EB'}}>Contact Us</TextXLarge></Col>
            <Col sm={2}></Col>
            <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}><TextXLarge style={{color:'#F7F5EB'}}>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition.</TextXLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '300px' }}>
            <Col sm={4}></Col>
            <Col sm={4}><TextXLarge style={{ color:'#F7F5EB'}}>© 2020, TypedSystem™. All Rights Reserved.</TextXLarge>
            <TextXLarge style={{color:'#F7F5EB'}}>
              For new business enquiries contact<br/>
              <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Terms & Conditions</a><br/>
              <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Privacy Policy</a>
            </TextXLarge>
            </Col>
          </Row>
        </div>
      </Container>
    </BackgroundLarge>

)

export default AboutLarge;
