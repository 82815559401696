import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { BackgroundSmall } from './About.style';
import { TextLarge } from './About.style';
import { TextLargeClean } from './About.style';
import { TitleLarge } from './About.style';
import { LetterOne } from './About.style';
import { LetterTwo } from './About.style';
import { LetterThree } from './About.style';
import { LogoTiny } from './About.style';
import { HeadlineSmall } from './About.style';
import { HeadlineSmallBottom } from './About.style';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const AboutSmall = () => (

      <BackgroundSmall>
        <Container fluid>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={12} style={{ height: '50vw', display: 'flex' }}><HeadlineSmallBottom>We Inspire Progress</HeadlineSmallBottom></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={12}><HeadlineSmall>We Inspire Progress</HeadlineSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={12}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={12}><HeadlineSmall>Business Artistry</HeadlineSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={2}></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={12}><HeadlineSmall>This is the foundation of<br></br>Business Artistry.</HeadlineSmall></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '50vw' }}>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={6} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
        </Container>
      </BackgroundSmall>

)

export default AboutSmall;
