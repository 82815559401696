import React from 'react';
import MediaQuery from 'react-responsive';
import WorksLarge from './Works/WorksLarge'


const Breakpoints = { large: 1200, medium: 900, small: 500 }

const Works = () => (

    <div>

        {/* LARGE

        <MediaQuery minWidth={1200}>*/}
            <WorksLarge/>
        {/*</MediaQuery>

         MEDIUM

        <MediaQuery minWidth={801} maxWidth={1199}>
            <AboutMedium/>
        </MediaQuery>

         SMALL

        <MediaQuery minWidth={1} maxWidth={800}>
            <AboutSmall/>
        </MediaQuery>*/}

    </div>
)

export default Works;
