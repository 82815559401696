import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { BackgroundMedium } from './About.style';
import { TextLarge } from './About.style';
import { TextLargeClean } from './About.style';
import { TitleLarge } from './About.style';
import { LetterOne } from './About.style';
import { LetterTwo } from './About.style';
import { LetterThree } from './About.style';
import { LogoTiny } from './About.style';
import { HeadlineLarge } from './About.style';
import { HeadlineLargeBottom } from './About.style';
import { NumberMedium } from './About.style';


setConfiguration({ breakpoints:[1,2,3,4], containerWidths:[1,2,3,4] });

const AboutMedium = () => (

      <BackgroundMedium>
        <Container fluid>
          <Row align="start" justify="start" style={{ height: '10vw' }}>
          </Row>
          <Row align="start" justify="start" style={{ height: '10vw' }}>
            <Col sm={3}></Col>
            <Col sm={6}><NumberMedium>1</NumberMedium></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}></Col>
            <Col sm={9} style={{ height: '25vw', display: 'flex' }}><HeadlineLargeBottom>A perfect symbiosis of</HeadlineLargeBottom></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={12}><HeadlineLarge>design and technology fueling<br/>the future of your brand</HeadlineLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={3}></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={6}></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>


          <Row align="start" justify="start" style={{ height: '20vw' }}>
            <Col sm={6}></Col>
            <Col sm={6}><NumberMedium>2</NumberMedium></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '10vw' }}>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={6}></Col>
            <Col sm={6}><HeadlineLarge>Multidisciplinary capabilities</HeadlineLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>

          <Row align="start" justify="start" style={{ height: '20vw' }}>
            <Col sm={3}></Col>
            <Col sm={6}><NumberMedium>3</NumberMedium></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '10vw' }}>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}></Col>
            <Col sm={9}><HeadlineLarge>Continuous colaboration<br></br>processes</HeadlineLarge></Col>
          </Row>
          <Row align="start" justify="start" style={{ height: '25vw' }}>
            <Col sm={3}><TextLarge>Who We Are</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
            <Col sm={3} style={{paddingRight: '2px'}}><TextLarge>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
          </Row>

                  <div style={{ backgroundColor:'#1F1F1F', padding:'15px', margin:'-15px', marginTop: '100px'}}>
                    <Row align="start" justify="start" style={{ height: '80px', marginTop: '100px' }}>
                      <Col sm={2}></Col>
                    </Row>
                    <Row align="start" justify="start" style={{ height: '20vw' }}>
                      <Col sm={3}></Col>
                      <Col sm={6}><NumberMedium style={{ color:'#F7F5EB'}}>4</NumberMedium></Col>
                    </Row>
                    <Row align="start" justify="start" style={{ height: '10vw' }}>
                    </Row>

                    <Row align="start" justify="start" style={{ height: '150px' }}>
                      <Col sm={2}><TextLarge style={{ color:'#F7F5EB'}}>Contact Us</TextLarge></Col>
                      <Col sm={2}></Col>
                      <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}>
                        <TextLarge style={{color:'#F7F5EB'}}>
                          Bergen<br/>
                          Hordaland<br/>
                          Norway
                        </TextLarge>
                      </Col>
                      <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}>
                        <TextLarge style={{color:'#F7F5EB'}}>
                          <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>info@typed.systems</a>
                        </TextLarge>
                      </Col>
                    </Row>
                    <Row align="start" justify="start" style={{ height: '150px' }}>
                      <Col sm={2}><TextLarge style={{ color:'#F7F5EB'}}>Work with us</TextLarge></Col>
                      <Col sm={2}></Col>
                      <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}>
                        <TextLarge style={{color:'#F7F5EB'}}>
                          For new business enquiries contact<br/>
                          <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Darek Nyckowiak</a>
                        </TextLarge>
                      </Col>
                    </Row>
                    <Row align="start" justify="start" style={{ height: '150px' }}>
                      <Col sm={2}><TextLarge style={{ color:'#F7F5EB'}}>Folllow Us</TextLarge></Col>
                      <Col sm={2}></Col>
                      <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}>
                        <TextLarge style={{color:'#F7F5EB'}}>
                          <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Facebook</a><br/>
                          <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Twitter</a><br/>
                          <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>Instagram</a><br/>
                          <a href="info@typed.systems" style={{ color:'#F7F5EB', textDecoration: 'underline'}}>LinkedIn</a><br/>
                        </TextLarge>
                      </Col>
                    </Row>
                    <Row align="start" justify="start" style={{ height: '350px' }}>
                      <Col sm={2}><TextLarge style={{ color:'#F7F5EB'}}>Contact Us</TextLarge></Col>
                      <Col sm={2}></Col>
                      <Col sm={3} style={{paddingRight: '2px', color:'#F7F5EB'}}><TextLarge style={{color:'#F7F5EB'}}>Because we strongly believe that magic happens on the interface between the linear and non-linear, the rational and irrational, realism and intuition. We also believe in meaningful work and meaningful relationships.</TextLarge></Col>
                    </Row>
                  </div>
        </Container>
      </BackgroundMedium>

)

export default AboutMedium;
